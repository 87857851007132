

import React, { useEffect, useState } from 'react'

import { db, storage, auth } from "./firebase";  

import elephant from './Images/elephantChess.png'

import leopard from './Images/leopardLogoBlack.png'


import { useHistory } from 'react-router-dom'


import { v4 as uuidv4 } from 'uuid';


import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import ReactPlayer from 'react-player';

import LeopardsUsersCard from './LeopardsUsersCard';

import moment from 'moment';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ScheduleCitySelectionMenu from './ScheduleCitySelectionMenu';
import BaseCitySelectionMenu from './BaseCitySelectionMenu';



const options = [
  "Female",
  "Male",
  "Trans",
];




  export default function CreateAccountPage(props) {




useEffect(() => {

 const name = localStorage.getItem("profileName")

if(name !== null){
 setProfileName(name)
}
         }, [])


         useEffect(() => {

            
 getUsersInfo()
           
                    }, [])



    const history = useHistory()



    const [startDate, setStartDate] = useState();

    const [endDate, setEndDate] = useState();


  const [image, setImage] = useState(elephant);


  const [url, setUrl] = useState("");
  const [progress, setProgress] = useState(0);


const [images, setImages] = useState([]);
const [urls, setUrls] = useState([]);
const [selectedImages, setSelectedImages] = useState([]);



const [pastSchedules, setPastSchedules] = useState([]);


const [inCallRates, setInCallRates] = useState();
const [outcallRates, setOutCallRates] = useState();



const [videoUrls, setVideoUrls] = useState([])


const [specialRates, setSpecialNotesAboutRates] = useState();



const [videoUrl, setVideoUrl] = useState("");


const [profileImage, setProfileImage] = useState();



const [profileName, setProfileName] = useState();



const [aboutMe, setAboutMe] = useState();



const [phoneNumber, setPhoneNumber] = useState("Phone Number");





const [city, setCity] = useState(0);


const [cityBasedIn, setCityBasedIn] = useState();




const [gender, setGender] = useState();




const [scheduleCount,setScheduleCount] = useState();




const [schedules, setSchedules] = useState([]);


const [showBaseCityOrNot, setShowBaseCityOrNot] = useState(true);


const getSchedules = () => {

  getUsersInfo()

}



const updateShowMainLocation = () => {
  
  if(showBaseCityOrNot === true){
    setShowBaseCityOrNot(false)



    db.collection("users").doc(profileName).update({"showBaseCity": false }).then((doc) => {
     alert("You Will Now Not Be Shown In Any Based In City Searches")
    }).catch((error) => {
    alert("Error Updating Show Base City Preference")
                        
    });



     

  } else {

    if(showBaseCityOrNot === false){


if(cityBasedIn === undefined){
alert("please select a city to be based in")
  return
}

      setShowBaseCityOrNot(false)


      db.collection("users").doc(profileName).update({"showBaseCity": true, baseCity: cityBasedIn }).then((doc) => {
        alert(`You Will Now Be Shown In Any Based City Searches for ${cityBasedIn}`)
       }).catch((error) => {
       alert("Error Updating Show Base City Preference")
                           
       });
    }


    
  }
}


   
  const baseCityMarkUp = showBaseCityOrNot === true? (
    <div>
        
        <p
style={{fontSize:"5vh", color:"white", marginTop:"3vh", marginBottom: "3vh"}}
> {profileName} are now being displayed for searches based in {city}  </p>


<button
       style={{
       backgroundcolor:"red",
height:"4vh",
color: "white",
width:"10vw",
backgroundColor:"black",
margin: "auto",
fontSize: "20px",
borderRadius: "5px",
border:"solid",
borderColor:"white",
marginBottom:"1vh",
marginTop:"1vh",

}}

       onClick={() => updateShowMainLocation()}> Change </button>


    </div>
    ) : (

        <div>
        
        <p
style={{fontSize:"5vh", color:"white", marginTop:"3vh", marginBottom: "3vh"}}
> You Profile Is Not Being Displayed In Any Searches - Only Schedules Will Be Shown </p>

      <button
      style={{
      backgroundcolor:"red",
height:"4vh",
color: "white",
width:"10vw",
backgroundColor:"black",
margin: "auto",
fontSize: "20px",
borderRadius: "5px",
border:"solid",
borderColor:"white",
marginBottom:"1vh",
marginTop:"1vh",

}}

      onClick={updateShowMainLocation}> Change </button>

    </div>
    )


  





useEffect(() => {
getUsersProfileImages()
getUsersInfo()
     }, []);
   
     

     

    
       const makeProfileIamge = (url) => {
        db.collection("users").doc(profileName).update({"profileImage": url }).then((doc) => {
          getUsersInfo()
        alert("Profile Picture Updated")
        }).catch((error) => {
        alert("Error Updating Profile Image" + error)
                            
        });
         }

           
         
  const pull_data = (data) => {
    

    if(data !== null){
      setCity(data)
      }
  
  }

  

  const selectCityBasedIn = (data) => {
    if(data !== null){
      setCityBasedIn(data)
      }
  }



  const updateAboutMeToDatabase = () => {  

    db.collection("users").doc(profileName).update({"aboutMe": aboutMe }).then((doc) => {
      getUsersInfo()
    alert("About Me Updated")
    }).catch((error) => {
    alert("Error Updating About Me")
                        
    });
     }





       const firstCheckPointCheckIfAlreadyHaveScheduleInChosenCity = () => {

        if(auth.currentUser && auth.currentUser.email  === "benpalmer661@icloud.com"){
            alert("hi ben ")

            updateScheduleToDatabase()
            return 
        }


if(scheduleCount > 4 ){
  alert("5 Schedules Limit Reached")
  return
}



const startDateIsBeforeToday = moment(startDate).add(1, 'day').isBefore(moment(Date.now()))



const endDateIsBeforeStartDate = moment(endDate).isBefore(moment(startDate))

if(startDate === undefined){
  alert("Start Date Empty")
  return
}

if(endDate === undefined){
  alert("End Date Empty")
  return
}

const startDateWithInTwoMonths = moment(startDate).subtract(60, 'days').isAfter(moment(Date.now()))

const endDateWithInTwoMonths = moment(endDate).subtract(90, 'days').isAfter(moment(Date.now()))


if(startDateWithInTwoMonths === true){
  alert(
    `Invalid - Start Date ${moment(startDate).format('ll')} Is To Far Into The Future - Limit 2 Months - Today's Date ${moment(Date.now())}`
    )
  return
}


if(endDateWithInTwoMonths === true){
  alert(
    `Invalid - End Date ${moment(startDate).format('ll')} Is To Far Into The Future - Limit 3 Months - Today's Date  ${moment(Date.now())}`
    )
  return
}



if(startDateIsBeforeToday === true){
  alert(
    `Invalid - Start Date ${moment(startDate).format('ll')} Is Before Today ${moment(Date.now()).format('ll')}`
    )
  return
}


if(endDateIsBeforeStartDate === true){
  alert(
    `Invalid - End Date ${moment(endDate).format('ll')} Is Before The Start Date ${moment(startDate).format('ll')} `
    )
  return
}





        db.collection("users").doc(profileName).collection("schedule").where("city", "==", city)
        .get()
        .then((querySnapshot) => {


          if(querySnapshot.size <= 0 ){

            updateScheduleToDatabase()


          } else {
            alert("schedule already exists for this city")
          }
        
         
            
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });

       }



       const updateScheduleToDatabase = () => {


        var startDateString = moment(startDate).format('ll')

        var endDateString = moment(endDate).format('ll')

        startDateString = startDateString.substring(0, startDateString.length-6);

        endDateString = endDateString.substring(0, endDateString.length-6);


        if(!profileImage){
          alert("You need to set a profile image")
          return
        }

        if(profileImage === ""){
          alert("You need to set a profile image")
          return
        }
  
        db.collection("users").doc(profileName).collection("schedule").add({startDateString, endDateString, city, 
          startDate, endDate, "email":profileName, "profileImage":profileImage, "profileName":profileName, "gender":gender }).then((doc) => {

          let id = doc.id


        db.collection("users").doc(profileName).collection("schedule").doc(id).update({id})

          db.collection("schedules").doc(city).collection(gender).doc(id).set({startDateString,
             endDateString, city, startDate, endDate, "email":profileName, 
             "profileImage":profileImage,"profileName":profileName, "gender":gender }).then((doc) => {

            getUsersInfo()
          })

         
       
        }).catch((error) => {
        alert("Error Updating Schedule" + error)
                            
        });

       }

       

       const updateGenderToDatabase = () => {

        alert("updateGenderToDatabase called")
 
        if(!gender){
          alert("No Gender")
          return
        }
        
        if(gender === ""){
          return
        }
        
        if(gender === null){
          return
        }

        
                db.collection("users").doc(profileName).update({"gender": gender }).then((doc) => {
                  getUsersInfo()
                alert(`Gender Updated To Database as ${gender}`)
                }).catch((error) => {
                alert("Error Updating Gender To Database")
                                    
                });
                 }




       const updatePhoneNumberToDatabase = () => {

if(!phoneNumber){
  return
}

if(phoneNumber === ""){
  return
}

if(phoneNumber === null){
  return
}

        db.collection("users").doc(profileName).update({"phoneNumber": phoneNumber }).then((doc) => {
          getUsersInfo()
        alert("Phone Number Updated")
        }).catch((error) => {
        alert("Error Updating Profile Image")
                            
        });
         }












           const updateRates = () => {

          alert("update rates called for profile name: " + profileName)

       

            db.collection("users").doc(profileName).update({"inCallRates":inCallRates, "outcallRates":outcallRates?? "xo", "specialRates":specialRates?? "xo", }).then((doc) => {
           

                alert("rates updated")
              
            getUsersInfo()

        
                                               
                             
                         
          
          }).catch((error) => {
              
          alert("Error Updating Rates")
                                    
          
          });


            
           }

           

           const getUsersInfo = () => {

            getUsersProfileImages()

               setSchedules([])

               setPastSchedules([])

            db.collection("users").doc(profileName).get().then((doc) => {
              // Document was found in the cache. If no cached document exists,
              // an error will be returned to the 'catch' block below.
              console.log("Cached document data:", doc.data());
          
             
              db.collection("users").doc(profileName).collection("schedule")
              .get()
              .then((querySnapshot) => {


                setScheduleCount(querySnapshot.docs.length)

                  querySnapshot.forEach((doc) => {
                      // doc.data() is never undefined for query doc snapshots
                      console.log(doc.id, " => ", doc.data());


          const wasTheEndDateYesterdayOrBefore = moment(Date.now()).subtract(1, 'day').isAfter(moment(doc.data().endDate.toDate()))





if(wasTheEndDateYesterdayOrBefore === true){

    console.log("will go to past schedules ")
    
    
                const object = {id: doc.id, profileImage:doc.data().profileImage,email:doc.data().email, startDate:doc.data().startDate
                  , endDate:doc.data().endDate, city:doc.data().city, profileName: doc.data().profileName, startDateString: doc.data().startDateString,
                  endDateString: doc.data().endDateString, gender: doc.data().gender,
                } 
         
                setPastSchedules((prevState) => [...prevState, object]); 
    
      return
    
    }
    



const wasTheEndDateWasMoreThanAWeekAgo = moment(Date.now()).subtract(7, 'day').isAfter(moment(doc.data().endDate.toDate()))


          if(wasTheEndDateWasMoreThanAWeekAgo === true){
            alert(`end date was yesterday so we will not include this post and delete it - doc.id is: ${doc.id}`)


            db.collection("users").doc(doc.data().email).collection("schedule").doc(doc.id).delete()
            .then(() =>{
              alert("old document deleted succesfully will delete the next one")
            

            }).catch((err) => {
            alert("Error 234234234: " + err)
            })


            db.collection("schedules").doc(city).collection(gender).doc(doc.id).delete().then(() =>{
              alert("old document deleted succesfully will delete the next one")

             
            }).catch((error) => {
              alert("345133445 error "+ error)
            });

            return

          }


                     

                      const object = {id: doc.id, profileImage:doc.data().profileImage,email:doc.data().email, startDate:doc.data().startDate
                        , endDate:doc.data().endDate, city:doc.data().city, profileName: doc.data().profileName ,startDateString: doc.data().startDateString,
                         endDateString: doc.data().endDateString, gender: doc.data().gender,}

               
                      setSchedules((prevState) => [...prevState, object]); 



                  });
              })
              .catch((error) => {
                  console.log("Error getting documents: ", error);
              });




              if(!doc.data().email){
                                
        db.collection("users").doc(profileName).update({"email": profileName}).then(() => {


         alert("email updated to database as: " + profileName)

      }).catch((error) => {
        alert("error updating email to database")
      });


                } 



  if(doc.data().profileName){
    setProfileName(doc.data().profileName)
    } else {
      alert("Please Choose A Profile Name")
    }

    

    if(doc.data().aboutMe){
      setAboutMe(doc.data().aboutMe)
                    }



              if(doc.data().inCallRates){
              setInCallRates(doc.data().inCallRates)
              } else {
              alert("no incall rates")
              }



              if(doc.data().gender){
                setGender(doc.data().gender)
                              }

              if(doc.data().outcallRates){
                setOutCallRates(doc.data().outcallRates)
                              }


                              if(doc.data().phoneNumber){
                                setPhoneNumber(doc.data().phoneNumber)
                                              } else {
                                                alert("You have Not set a phone number")
                                              }
                
              

              if(doc.data().specialRates){
                setSpecialNotesAboutRates(doc.data().specialRates)
                              } 


                              if(doc.data().profileImage){
                                setProfileImage(doc.data().profileImage)
                                              } 
                

                                            


                            
          }).catch((error) => {
              console.log("Error getting cached document:", error);
      
          
          });
        }









        useEffect(() => {
  ///bpxxxx put this up when going live
//           if(auth.currentUser && auth.currentUser.email  === "benpalmer661@icloud.com"){
// alert("not ben palmer will return to home page" )

// history.push('/')

// }
            
                 }, []);
      
      




     useEffect(() => {
  

      getUsersVideoUrls()
      
           }, []);






const getUsersVideoUrls = () => {

  setVideoUrls([])

  db.collection("users").doc(profileName).collection("videoUrls").get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());

        console.log(doc.data().url)

        console.log(doc.data().docId)

        const object = {id: doc.id, url:doc.data().url}
        
        setVideoUrls((prevState) => [...prevState, object]); 

    });
});
}

         


const getUsersProfileImages = () => {

  setUrls([])

  if(auth.currentUser){
  if(auth.currentUser.phoneNumber){
    auth.signOut()

    return
}} else {
  window.location.href = '/';
}

  
  db.collection("users").doc(profileName).collection("profileImages").get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());



        console.log(doc.data().profilePictureURL)

        const profileImage = doc.data().profilePictureURL
        


        const object = {id: doc.id, url:doc.data().profilePictureURL, private: doc.data().private}

        setUrls((prevState) => [...prevState, object]); 

    });
}).catch((error) =>{
    alert("error getting profile images " + error)
})
}









const getUsersProfileImage = () => {

  db.collection("users").doc(profileName).update({"profileImageUrl":url})
  
}














function deleteHandler(image) {
    
  setSelectedImages(images.filter((e) => e !== image));
  URL.revokeObjectURL(image);
  setImages(images.filter((e) => e !== image));

}






















        






  const types =["image/png","image/jpeg"]






      const deleteVideoFromProfile = (id) => {



        db.collection("users").doc(profileName).collection("videoUrls").doc(id).delete().then(() => {
          getUsersVideoUrls()
          console.log("Document successfully deleted!");

      }).catch((error) => {
          console.error("Error removing document: ", error);
      });

      }
    








      const deleteFromProfile = (url) => {

        setUrls([])


        db.collection("users").doc(profileName).collection("profileImages").doc(url.id).delete().then(() => {
          getUsersProfileImages()
          alert("Image successfully deleted!");

      }).catch((error) => {
          console.error("Error removing document: ", error);
      });

      }
    


  
  /// bpxxx on this page instead of update profile name we want search profile name and then get all the details from that document
  // then we want to be able to update doc as normal  and figure out why the picture are being upload properly

  const makeMainProfileImage = (url) => {

    console.log(url.url)

    db.collection("users").doc(profileName).update({"profileImageUrl":url.url})

  }

















  



  const handleChange = (e) => {
console.log("handlechange called")

    for (let i = 0; i < e.target.files.length; i++) {

     
      if(e.target.files[i].size){ 
        if(e.target.files[i].size/1000 > 2000){
          alert("File must be under 2MB in size")
          return
        }
}


      if(e.target.files[i].type !==  "image/jpeg"){
        if(e.target.files[i].type !==  "image/png"){
          alert("please only chose jpeg or png")
          return
 }

       
      }

      const newImage = e.target.files[i];



      newImage["id"] = Math.random();
      const imageUrl = URL.createObjectURL(e.target.files[i]);
      newImage.url = imageUrl
      // setSelectedImages((previousImages) => previousImages.concat(imageUrl));

      setImages((prevState) => [...prevState, newImage]); 
    }
    e.target.value = "";
  };










const [anchorEl, setAnchorEl] = React.useState(null);
const [selectedIndex, setSelectedIndex] = React.useState(1);


const handleClickListItem = (event) => {
  setAnchorEl(event.currentTarget);
};




useEffect(() => {
setGender(options[selectedIndex])

}, [selectedIndex])
// }, [selectedIndex,props],[])




useEffect(() => {
setSelectedIndex([0]);

}, [])








const handleMenuItemClick = (event, index) => {
  setSelectedIndex(index);
  setAnchorEl(null);
};


const handleClose = () => {
  setAnchorEl(null);
};






  const handleUploadMultiple = () => {
    const promises = [];
  


if (images === null){
  alert("Please Click Add Images Then Upload")
  return
}

       images.map((image) => {

        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        async () => {
          await storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then((urls) => {

              addUrlsToFirebase(urls)

            
          
            })
        }
      );
    });

    Promise.all(promises)
      .then(() =>
   console.log("fun")
     
      
      ).then(() =>
setImages(null)

      )
      
      
      .catch((err) => console.log(err));
  };
  

  


  const uploadVideo = (theVidUrl) => {

 

    var vidUrl = theVidUrl.replace(/(^\w+:|^)\/\//, '');


    const id = uuidv4()

  
    db.collection("users").doc(profileName).collection("videoUrls").doc(id).update({"url": vidUrl, "docId":id}).then(() => {
     
      alert("Video Url Updated To Database")
      getUsersVideoUrls()
   
    }).catch((error) => {
    alert("Error Updating Video Url")
                              
    
    });
    }
  







const addUrlsToFirebase = (urls) => {

  setUrls([])

  if(!profileName){
    alert("no profile name f3g4 ")
    return 
  }

  if(profileName === ""){
    alert("profile name is empty string ")
    return 
  }

  db.collection("users").doc(profileName).collection("profileImages").doc().set({"profilePictureURL":urls,"private":true}, { merge: true })


  getUsersProfileImages()

}







    
    
    
    

  





const updateProfileNameToDatabase = () => {

 


  if(profileName === ""){
    alert("Profile Name Empty")
    return
  }

  if(!profileName){
    return
  }
  
  if(profileName === null){
    return
  }

  localStorage.setItem("profileName",profileName)

  db.collection("users").doc(profileName).update({"profileName": profileName, "accountCheckedForQuality":true, gender: "Female"}).then((doc) => {
    getUsersInfo()
  alert(`Profile Name Updated As ${profileName} `)
  }).catch((error) => {
  alert("34531451 - Error Updating Profile Name" + error)


 


  alert("updateProfileNameToDatabase called")
  
  const s = uuidv4()
   
  const id =  s.substring(0, 1)




  db.collection("users").doc(profileName).set({"profileName": profileName, "accountCheckedForQuality": true, gender: "Female",password:`${2334+id}`,"inCallRates":"xo", "outcallRates":"xo",
   "specialRates":" For Rates Please Text Me - Leopard Escorts Enquiry, Age , Nationality, Your City.","signUpDate": Date.now()}).then((doc) => {
    getUsersInfo()
  alert(`Profile Name Updated As ${profileName} `)
  }).catch((error) => {
  alert("3453454 - Error Updating Profile Name" + error)
  })

  })
}




  

    




  
  return (
    <center>
    <div
    style={{ backgroundColor:"black"}}
    >
    


    
      


     


<center>
   

<div 
style={{height:"50vh",width:"50vw", 
// backgroundColor:"yellow",
padding:"2vw",
objectFit:"fill",
paddingTop:"200px",
marginBottom:"50vh",
}}
>

<p
style={{fontSize:"5vh", color:"white", marginBottom: "1vh"}}
>
{profileName}
</p>

<input
placeholder='Edit Profile Name'
style={{width:"20vw", height:"3vh", marginBottom:"1vh"}}
onChange={e => setProfileName(e.target.value)}
>

</input>

<br/>

<button
       style={{
       backgroundcolor:"red",
height:"30px",
color: "white",
width:"20vw",
backgroundColor:"black",
margin: "auto",
fontSize: "1.5vh",
borderRadius: "5px",
border:"solid",
borderColor:"white",
marginBottom:"1vh",
marginTop:"1vh",

}}
       onClick={() => updateProfileNameToDatabase()}>Update Profile Name To Database </button>
 



 <br/>
 <button
       style={{
       backgroundcolor:"red",
height:"30px",
color: "white",
width:"20vw",
backgroundColor:"black",
margin: "auto",
fontSize: "1.5vh",
borderRadius: "5px",
border:"solid",
borderColor:"white",
marginBottom:"1vh",
marginTop:"1vh",

}}
       onClick={() => getUsersInfo()}> Get User Info </button>
 

 <br/>

      <img
      style={{height:"100%",
       objectFit:"contain",
       marginBottom:"5vh",
       height:"50vh",
       width:"50vw", 
       }}
     
       src={profileImage || leopard}
       
        />



       </div>


</center>








<div>

</div>










{progress > 0 &&  progress < 100 &&

  <progress value={progress} max="100"
      style={{color:"lightBlue"}}
       />

      }

    
      <br /> 








      <section>
   
    

      <div className="images"
      style={{display:"flex", flexWrap:'wrap'}}
      >
        {images &&
          images.map((image, index) => {
            return (
              <div key={image.url} className="image"
              style={{position:"relative"}}

              >
              
            
                <img src={image.url} 
                alt="upload" 
                style={{
                    float: "left",
                    objectFit:"contain",
    width:  "24.5vw",
    height: "25vh",
    backgroundSize: "cover",
    marginBottom:"2vh",
                borderStyle:"solid",
                borderColor:"yellow",
 marginLeft:'1px', 
 position:"relative"
    
    }}
                />

                <button 
                
                onClick={() => deleteHandler(image)}
                style={{
                  position:"absolute" ,
                 right:"1px",
                 top:"1px",
                backgroundColor:"white",
                borderStyle:"solid",

                
                }}>
                  X
                </button>
              </div>
            );
          })}
      </div>



      <div className="images"
      style={{display:"flex"}}
      >
        {images &&
          images.map((image, index) => {
            return (
              <div key={image.id} 
              // className="image"
              style={{
               position:"relative"
                }}
              >
               
               

                <button onClick={() => deleteHandler(image)}
                style={{
                  position:"absolute" ,
                 right:"1px",
                 top:"1px",
                backgroundColor:"white",
                borderStyle:"solid",
                
                }}>
                  X
                </button>
              </div>
            );
          })}
      </div>




    </section>


    <br/>
<br/>

 <div

style={{

color: "white",
width:"20vw",
height:"30px",
backgroundColor:"black",
fontSize: "20px",
borderRadius: "5px",
border:"solid",
borderColor:"white",

}}
>


    <label
// need to do a boolean with url.private and if private make grey and save private then hae a button to clicck to make private or public etc
 
      >
        + Add Images 
 
        <input 
        type="file"
         multiple
          onChange={handleChange} 
          
            hidden
            style={{
width:"20vw",


}}
          />

      </label>
      </div>
  

    
      
      
      <button
       style={{
       backgroundcolor:"red",
height:"30px",
color: "white",
width:"20vw",
backgroundColor:"black",
margin: "auto",
fontSize: "20px",
borderRadius: "5px",
border:"solid",
borderColor:"white",
marginBottom:"1vh",
marginTop:"1vh"
}}
       onClick={() => handleUploadMultiple()}>Upload </button>





      <div  style={{display:"flex",  flexWrap:"wrap", marginTop:"10vh"}}>


{urls.map((url, i) => (
  <div key={i} className="image"
        style={{margin:"1vh",
      position:"relative"
        }}
        >
          <img src={url.url} 
          // height="200" 
          alt="upload" 


          style={{
            objectFit:"contain",
width:  "46.7vw",
height: "48.5vh",
backgroundSize: "cover",
borderStyle:"solid",
          borderColor:"white",
          padding:"1vh",
          }}
          />


          <button onClick={() => deleteFromProfile(url)}
         
          style={{
            position:"absolute" ,
           right:"5px",
           top:"5px",
          borderStyle:"solid",
      color: "white",
      backgroundColor:"black",
      borderRadius: "5px",
      border:"solid",
      borderColor:"white",
      padding:"1vh",
      
          }}>
            X
          </button>




          {/* {url.private === true ? (
            <button onClick={() => makePublic(url.id)}
         
         style={{position:"absolute" ,
          right:"40px",
          top:"5px",
         backgroundColor:"white",
         borderStyle:"solid",
      
         
         }}>
           Make Public
         </button>

         
      ) : (
        <button onClick={() => makePrivate(url.id)}
         
         style={{position:"absolute" ,
          right:"40px",
          top:"5px",
         backgroundColor:"white",
         borderStyle:"solid",

         }}
         >
          Make Private
         </button>
      )} */}





      {/* {url.private === true ? (
          <p
           style={{position:"absolute" ,
          right:"50%",
          top:"50%",
         padding:"2vh",
         borderStyle:"solid",

         }}
          >IMAGE PRIVATE</p>
         
      ) : (
      null
      )} */}








      <button onClick={() => makeProfileIamge(url.url)}
         
         style={{position:"absolute" ,
          left:"5px",
          top:"5px",
         borderStyle:"solid",
      color: "white",
      backgroundColor:"black",
      borderRadius: "5px",
      border:"solid",
      borderColor:"white",
      padding:"1vh"
         }}>
           Make Profile Image
         </button>     



      {/* {url.private === false ? (
            <button onClick={() => makeProfileIamge(url.url)}
         
         style={{position:"absolute" ,
          left:"5px",
          top:"5px",
         backgroundColor:"white",
         borderStyle:"solid",
      color: "white",
      backgroundColor:"black",
      borderRadius: "5px",
      border:"solid",
      borderColor:"white",
      padding:"1vh"
         }}>
           Make Profile Image
         </button>         
      ) : (
       null
      )} */}







        </div>
        
))}
</div>

      <br/>
    <br/>


     
<center>

<p
style={{fontSize:"5vh", color:"white", marginTop:"3vh", marginBottom: "1vh"}}
>
VIDEOS
</p>


<p
style={{fontSize:"2vh", color:"white", marginBottom: "1vh"}}
>
INSERT YOUTUBE VIDEO URL
</p>

<input
style={{width:"50vw", height:"2vh", marginBottom:"1vh"}}
onChange={e => setVideoUrl(e.target.value)}
>

</input>

<br/> 

<button
onClick={() => uploadVideo(videoUrl)}
style={{
       backgroundcolor:"red",
height:"30px",
color: "white",
width:"20vw",
backgroundColor:"black",
margin: "auto",
fontSize: "1vw",
borderRadius: "5px",
border:"solid",
borderColor:"white",
marginBottom:"1vh",
marginTop:"1vh",

}}
>
  UPLOAD VIDEO URL TO DATABASE
</button>

<br/>
<br/>



<div className="images"
      style={{display:"flex",  flexWrap:"wrap"}}
      >


{videoUrls.map((url, i) => (
<div
style={{
  //backgroundColor:"red", 
width:"49vw"}}
>
  <ReactPlayer
 
 url={url.url}
 controls={true}
 style={{padding:"1vh",width:"49vw",borderstyle:"solid", borderColor:"white"}}
 >
 
 </ReactPlayer>
 <button
 style={{
      
height:"30px",
color: "white",
width:"37vw",
backgroundColor:"black",
margin: "auto",
fontSize: "20px",
borderRadius: "5px",
border:"solid",
borderColor:"white",

}}

onClick={() => deleteVideoFromProfile(url.id)}
 >
  Delete Video
  </button>
 </div>

))}

</div>




<br/>
<br/>
<br/>
<br/>
<div

style={{borderstyle:"solid",
borderColor:'white',
backgroundColor:"grey"
}}
>


<br/>
<br/>



<p
style={{fontSize:"5vh", color:"white", marginTop:"3vh", marginBottom: "3vh"}}
> Main Location - {cityBasedIn}</p>


<BaseCitySelectionMenu
func={selectCityBasedIn}
/>

<br/>
<br/>

{/* <p
style={{fontSize:"5vh", color:"white", marginTop:"3vh", marginBottom: "3vh"}}
> Show Me In Searches For Selected City? </p> */}



{baseCityMarkUp}

<br/>
<br/>
<br/>
<br/>

</div>

<br/>
<br/>
<br/>
<br/>


<p
style={{fontSize:"5vh", color:"white", marginTop:"3vh", marginBottom: "3vh"}}
>
RATES
</p>



{/* {ratesPrivate === true ? (

  <button
    style={{
       backgroundcolor:"red",
height:"30px",
color: "white",
width:"20vw",
backgroundColor:"black",
margin: "auto",
fontSize: "20px",
borderRadius: "5px",
border:"solid",
borderColor:"white",
marginBottom:"1vh",

}}
  onClick={() => setRatesToPublicInDatabase(false)}
  
  > RATES PRIVATE</button>
 ):(<button
   style={{
       backgroundcolor:"red",
height:"30px",
color: "white",
width:"20vw",
backgroundColor:"black",
margin: "auto",
fontSize: "20px",
borderRadius: "5px",
border:"solid",
borderColor:"white",
marginBottom:"1vh",

}}
  onClick={() => setRatesToPrivateInDatabase(true)}
 > RATES PUBLIC</button>
 )} */}











 <div

style={{

color: "white",
backgroundColor:"black",
margin: "auto",
fontSize: "20px",
borderRadius: "5px",
border:"solid",
borderColor:"white",
display:"flex",
 }}



>
  
   

    
   <div
   style={{
    backgroundColor:"black",
    borderStyle:"solid",
 borderColor:"white",
 padding:"1vh"
   }}
   >


INCALL RATES 
<br/>
<p
style={{fontSize:"1.5vh"}}
>
(Seperate With Comma)
</p>

    
   <textarea
   style={{
    height:"20vh",
    width:"32vw",
    //backgroundColor:"blue",
    
   }}
 
   value={inCallRates}
onChange={e => setInCallRates(e.target.value)}
   >
  
 


   </textarea>
   </div>

   <br/>

   <div
   style={{
    borderStyle:"solid",
 borderColor:"pink",
    backgroundColor:"black",
    padding:"1vh"
   }}
   >


OUTCALL RATES
<br/>
<p
style={{fontSize:"1.5vh"}}
>
(Seperate With Comma)
</p>


   <textarea
   style={{
    height:"20vh",
    width:"32vw"
   }}
   value={outcallRates}
onChange={e => setOutCallRates(e.target.value)}
   >
  

   </textarea>
   </div>



   <div
   style={{
    backgroundColor:"black",
    padding:"1vh",

   }}
   >

SPECIAL NOTES ABOUT RATES

<br/>
<p
style={{fontSize:"1.5vh",color:"black"}}
>
Leopard
</p>
    
   <textarea
   style={{
    height:"20vh",
    width:"29vw"
   }}
onChange={e => setSpecialNotesAboutRates([e.target.value])}
value={specialRates}


   >
  


   </textarea>

 
          {/* <ul
          style={{backgroundColor:"black"}}
          >
            {outcallRates.split(',').map((el,i) => <li key={i}>{el}</li> )}
          </ul> */}
   </div>

      </div>


<br/>

      <button
       style={{
       backgroundcolor:"red",
height:"30px",
color: "white",
width:"20vw",
backgroundColor:"black",
margin: "auto",
fontSize: "20px",
borderRadius: "5px",
border:"solid",
borderColor:"white",
marginBottom:"1vh",
marginTop:"1vh",

}}
       onClick={updateRates}>Update Rates To Database </button>



<p
style={{fontSize:"10vh"}}
> PREVIEW</p>




   

                              
              
          



    <div


style={{display:"flex", }}
>
<div
style={{display:"flex", borderStyle:"solid", borderColor:"white"}}
>
<div
style={{width:"49.6vw", marginBottom:"2vh"}}
>
<p
style={{fontSize:"5vh", color:"white"}}
>
INCALL
</p>

          <ul
          style={{backgroundColor:"black"}}
          >

            {inCallRates&& inCallRates.split(',').map((el,i) => <li
            style={{fontSize:"2vh", color:"white", marginTop:"2vh"}}
             key={i}>{el}</li> )}
          </ul>




</div>

</div>


<div
style={{display:"flex", flexWrap:"wrap", borderStyle:"solid", borderColor:"white"}}
>
<div
style={{width:"50vw"}}
>
<p
style={{fontSize:"5vh", color:"white"}}
>
OUTCALL
</p>

<ul
          style={{backgroundColor:"black"}}
          >
            {outcallRates&& outcallRates.split(',').map((el,i) => <li
            style={{fontSize:"2vh", color:"white", marginTop:"2vh"}}
             key={i}>{el}</li> )}
          </ul>

          


</div>

</div>

</div>

<div
// style={{width:"49vw"}}
style={{ borderStyle:"solid", borderColor:"white",fontSize:"3vh", color:"white" ,padding:"2vh"}}

>
<p
style={{fontSize:"5vh", color:"white" , marginTop:"5vh", marginBottom:"5vh"}}
>
SPECIAL NOTES
</p>

<p
style={{fontSize:"3vh", color:"white" , marginTop:"5vh", marginBottom:"5vh"}}
>
{specialRates}
</p>



<br/>

<br/>
</div>

<br/>
 

    <p
style={{fontSize:"5vh", color:"white" , marginTop:"5vh",}}
>
ABOUT ME
</p>



<p
style={{fontSize:"3vh", color:"white" , marginTop:"5vh",padding:"5vh"}}
>
{aboutMe}
</p>
   <br/>
   <br/>

<textarea
   style={{
    height:"20vh",
    width:"32vw",
    //backgroundColor:"blue",
    
   }}
 placeholder='Write A Little Bit About Yourself'
   value={aboutMe}
onChange={e => setAboutMe(e.target.value)}
   >
  
 


   </textarea>

<br/>

   <button
       style={{
       backgroundcolor:"red",
height:"30px",
color: "white",
width:"20vw",
backgroundColor:"black",
margin: "auto",
fontSize: "1.5vh",
borderRadius: "5px",
border:"solid",
borderColor:"white",
marginBottom:"1vh",
marginTop:"4vh",

}}
       onClick={() => updateAboutMeToDatabase()}>Update About Me To  Database </button>
 

 <br/>
 <br/>
 <br/>

    <div
    style={{borderstyle:"solid",
borderColor:'white',
backgroundColor:"grey"
}}
    >
    <br/>
    <p
style={{fontSize:"3vh", 
color:"white" , marginTop:"5vh",
}}
>
SELECT CITY & DATES THEN PRESS UPDATE TO UPDATE DATABASE
<br/>
<br/>

{/* {city} */}

<ScheduleCitySelectionMenu
func={pull_data}
/>
</p>



<center>
<div
 style={{display:"flex",  flexWrap:"wrap", marginTop:"3vh",

 }}
>
  
  <div
 style={{
  width:"24.5vw"}}
 > </div>


 <div
 style={{
  //backgroundColor:"blue",
  width:"24.5vw"}}
 >
START DATE
<br/>

<DatePicker 
selected={startDate} 
 onChange={(date) => setStartDate(date)}
 />

 </div>



 <div
 style={{
  //backgroundColor:"red", 
  width:"24.5vw"}}
 >
END DATE
<br/>

<DatePicker 
selected={endDate}
 onChange={(date) => setEndDate(date)} />
</div>


<div
 style={{
  //backgroundColor:"red",
  width:"24.5vw"}}
 > </div>

</div>

<button
       style={{
       backgroundcolor:"red",
height:"30px",
color: "white",
width:"20vw",
backgroundColor:"black",
margin: "auto",
fontSize: "1.5vh",
borderRadius: "5px",
border:"solid",
borderColor:"white",
marginBottom:"1vh",
marginTop:"4vh",

}}
       onClick={() => firstCheckPointCheckIfAlreadyHaveScheduleInChosenCity()}>Update Schedule To  Database </button>
 

</center>

<br/>

<br/>
<br/>


</div>

<br/>
<br/>


<div className="images"
      style={{display:"flex"}}
      >
        {schedules &&
          schedules.map((schedule, index) => {



            return (
             



<LeopardsUsersCard
getSchedules={getSchedules}
city = {schedule.city}
profileImage = {schedule.profileImage}
startDate = {schedule.startDate}
endDate = {schedule.endDate}
email = {schedule.email}
profileName = {schedule.profileName}
id = {schedule.id}
startDateString = {schedule.startDateString}
endDateString = {schedule.endDateString}
gender = {schedule.gender}
viewProfile={true}

style={{height:"25vh"}}


/>


            
            );
          })}
      </div>



      <br/>
<br/>
<br/>



      
{pastSchedules && pastSchedules.length > 0  ? (
    <p
style={{fontSize:"3vh", 
color:"white" , marginTop:"5vh",
}}
>Recently Past Schedules - Will Self Delete After 7 Days</p>

):( null )}



      <div className="images"
      style={{display:"flex"}}
      >



        {pastSchedules &&
            pastSchedules.map((schedule, index) => {



            return (
             

<LeopardsUsersCard
getSchedules={getSchedules}
city = {schedule.city}
profileImage = {schedule.profileImage}
startDate = {schedule.startDate}
endDate = {schedule.endDate}
email = {schedule.email}
profileName = {schedule.profileName}
id = {schedule.id}
startDateString = {schedule.startDateString}
endDateString = {schedule.endDateString}
gender = {schedule.gender}


style={{height:"25vh"}}


/>


            
            );
          })}
      </div>









   

<br/>
<br/>


<br/>
    <br/>

    <p
style={{fontSize:"5vh", color:"white" }}
    
    >Gender: {gender}
    </p>
    <br/>
   



    <div 
    
    >

  
      <List
className="button is-primary"
       style={{
        height: "5vh",
        width:"20vw",
        border: "1px solid #dfe1e5",
        borderRadius: "4px",
        boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
        hoverBackgroundColor: "#eee",
        fontSize: "2vw",
        fontFamily: "Arial",
        iconColor: "grey",
        lineColor: "rgb(232, 234, 237)",
        placeholderColor: "grey",
        padding:"0px",
       
        left:"0",
        textAlign:"center",
        color:"white",
backgroundColor:"black",



        
       }}
      component="nav" aria-label="Device settings">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          onClick={handleClickListItem}
        >
          <ListItemText 
          // primary={`${options[selectedIndex]} `} 
          primary={gender} 
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{
          // backgroundColor: "black",
          // marginTop:"99",
        
        }}
      >
        {options.map((option, index) => (
          <MenuItem
style={{
          width:"100vw",
          fontSize:"3vh"
        }}
            key={option}
            // disabled={index === 0}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
          


             {`${option}`} 
          
          </MenuItem>
        ))}
      </Menu>
    </div>




<button
       style={{
       backgroundcolor:"red",
height:"30px",
color: "white",
width:"20vw",
backgroundColor:"black",
margin: "auto",
fontSize: "1.5vh",
borderRadius: "5px",
border:"solid",
borderColor:"white",
marginBottom:"1vh",
marginTop:"2vh",

}}
       onClick={() => updateGenderToDatabase()}>Update Gender To Database </button>
 





<br/>
<br/>

<p
style={{fontSize:"5vh", color:"white", marginBottom: "1vh"}}
>
{phoneNumber}
</p>

<input
placeholder='Edit Phone Number'
style={{width:"20vw", height:"3vh", marginBottom:"1vh"}}
onChange={e => setPhoneNumber(e.target.value)}
>

</input>

<br/>

<button
       style={{
       backgroundcolor:"red",
height:"30px",
color: "white",
width:"20vw",
backgroundColor:"black",
margin: "auto",
fontSize: "1.5vh",
borderRadius: "5px",
border:"solid",
borderColor:"white",
marginBottom:"1vh",
marginTop:"1vh",

}}
       onClick={() => updatePhoneNumberToDatabase()}>Update Phone Number </button>
 


 <br/>
    <br/>

    <br/>
    <br/>


 <p
style={{fontSize:"3vh", color:"white", marginBottom: "1vh"}}
>
EMAIL:
</p>


 <p
style={{fontSize:"5vh", color:"white", marginBottom: "1vh"}}
>
{profileName}
</p>

<div>

</div> 
   


      <br/>
    <br/>


     
 


    <br/>

{progress > 0 &&  progress < 100 &&

<progress value={progress} max="100"
    style={{color:"lightBlue"}}
     />

    }

      <br />
</center>


    






    </div>








     


    </center>
  )

}


// put current country flag and original flag on this page , 



