



import React, {  useState } from 'react'


//import React, { useEffect, useState } from 'react'
import { auth, db } from './firebase';



import { useHistory } from 'react-router-dom'




export default function ReportUserPage() {


    const history = useHistory()



       
    const profileName = history.location.state?.profileName

 

const [text, setText] = useState('')




 
 
const handleSubmit = () => {
    
   

//     let timestamp = Date.now()


//       let timeStampForReportingUser = localStorage.getItem("timeStampForReportingUser")
  
//    if(timeStampForReportingUser){
 
//      var difference = timestamp - timeStampForReportingUser

//      var oneDayInTimestamp = 86400000 

//      if(difference < oneDayInTimestamp){
//         alert("Sorry only one message permitted per 24 hours")
//      } else {

//         // alert(`difference  ${difference}  oneDayInTimestamp ${oneDayInTimestamp}`)

//       localStorage.setItem("timeStampForReportingUser",timestamp)

//          sendMessage()
//      } 
     
// } else {
//     localStorage.setItem("timeStampForReportingUser",timestamp)
    sendMessage()
// }

 



}



const sendMessage = () => {

    const profileName = history.location.state?.profileName
    db.collection("ReportedUser").add({"text": text, "timestamp": Date.now(),profileName}).then(()=>{

alert("Message sent")
    }).catch((err) =>{
alert("2rv2454" + err)
    })

}
 

  return (

    <div
    style={{
        backgroundColor:"black", 
        paddingTop:"200px"}}
    >
    
    
<center>
    <p
     style={{fontSize:"50px", color:'white'}}
    >
    Report User: {profileName}
    </p>


<br/>

<p
     style={{fontSize:"3vh", color:'white'}}
    >
    Ensure You Are Happy With Your Report Before Clicking Send 
    <br/>
     Only One Report Can Be Made Per 24 Hours
    </p>
<br/>
<br/>



<textArea type="text"
                    style={{width:"70vw", height:"30vh", color:'black',fontSize:"2.5vh"}}
                    className="input"
                    placeholder="Enter Text"
                    value={text}
                    onChange={e => setText(e.target.value)}
                    required />

<br/>
<br/>
<br/>
<br/>

        <button 
       style={{height:"3vh", 
       width:"20%%",
     backgroundColor:"black",
     color:"white",
fontSize:"1.5vh",
marginBottom:"300px"
}}
        onClick={() => handleSubmit()}
        className="abort-game-button" > Send
        </button>



        <br/>
<br/>

<br/>
<br/>

<br/>
<br/>
        


</center>
        

</div>












  )
}
