



import React, { useEffect, useState } from 'react'

import LeopardsUsersCard from './LeopardsUsersCard';

import { db } from "./firebase";  


export default function AllUsersPage() {




const [users, setUsers] = useState([]);


const getSchedules = () => {
  alert("this is not set up on this page")
}




useEffect(() => {


getAllUsers()
 
          }, [])
 



          const getAllUsers = () => {


            db.collection("users").get()
            .then((querySnapshot) => {
        
            
                querySnapshot.forEach((doc) => {

              // un comment to delete docs without profile images
                  if(!doc.data().profileName){
                    console.log("no profile image for: " + doc.id)

                db.collection("users").doc(doc.id).delete().then(() =>{
                  console.log("doc delete successful")
                }).catch(( error) => {
               console.log("errror " + error)
                })
                  }


        ///bpxxx use this to update all users at once
       // db.collection("users").doc(doc.data().email).update({"accountCheckedForQuality": true})
            

      
       
                
                const object = {id: doc.id, profileImage:doc.data().profileImage,email:doc.data().email, startDate:doc.data().startDate
                  , endDate:doc.data().endDate, profileName: doc.data().profileName, startDateString: doc.data().startDateString,
                  endDateString: doc.data().endDateString, gender: doc.data().gender,
                } 
            

               
          
                setUsers((prevState) => [...prevState, object]); 
              })
              })
          }
        


 
  return (

  

<center>
    <div
    style={{ backgroundColor:"black", paddingTop:"10vh"}}
    >
    
    getAllUsersPage
    

    <div className="images"
      style={{display:"flex",
      backgroundColor:"black",
      flexWrap:"wrap"
      }}
      >
    

    {users &&
          users.map((schedule, index) => {
            
    
       
            return (
<LeopardsUsersCard
getSchedules={getSchedules}
city = {schedule.city}
profileImage = {schedule.profileImage}
startDate = {schedule.startDate}
endDate = {schedule.endDate}
startDateString = {schedule.startDateString}
endDateString = {schedule.endDateString}
email = {schedule.email}
profileName = {schedule.profileName}
gender = {schedule.gender}
id = {schedule.id}
viewProfile={true}
style={{height:"25vh"}}


/>
            );
          })}

</div>
    </div>
    </center>
  )
}
