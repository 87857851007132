

import React, { useEffect, useState } from 'react'

import { db, auth } from "./firebase";  



import ReactPlayer from 'react-player';



import leopardClear from './Images/leopardsolo.png'



import LeopardsUsersCard from './LeopardsUsersCard';

import { useHistory } from 'react-router-dom'


  export default function MyProfilePage(props) {




const [profileName, setProfileName] = useState("Leopard Escorts");


const [schedules, setSchedules] = useState([]);
    
  const [phoneNumber, setPhoneNumber] = useState("Phone Number");

  const [email, setEmail] = useState("Email");


const [images, setImages] = useState([]);

const [urls, setUrls] = useState([]);
const [selectedImages, setSelectedImages] = useState([]);




const [inCallRates, setInCallRates] = useState()
const [outcallRates, setOutCallRates] = useState()


const [videoUrls, setVideoUrls] = useState([])

const [specialRates, setSpecialNotesAboutRates] = useState();


const [rates, setRates] = useState([]);






const [profileImage, setProfileImage] = useState();



const [aboutMe, setAboutMe] = useState();








const getSchedules = () => {

  db.collection("users").doc(auth.currentUser.email).collection("schedule")
  .get()
  .then((querySnapshot) => {

      querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());


    const object = {id: doc.id, profileImage:doc.data().profileImage,email:doc.data().email, startDate:doc.data().startDate
      , endDate:doc.data().endDate, city:doc.data().city, profileName: doc.data().profileName, startDateString: doc.data().startDateString,
      endDateString: doc.data().endDateString, gender: doc.data().gender, aboutMe: doc.data().aboutMe
    } 

    setSchedules((prevState) => [...prevState, object]); 
    
  })
}).catch((error) => {
alert(`error: ${error}`)
})

 }







useEffect(() => {
getUsersProfileImages()
     }, []);
   
     

  

    
     


         
           const getUsersInfo = () => {

            db.collection("users").doc(auth.currentUser.email).get().then((doc) => {
              // Document was found in the cache. If no cached document exists,
              // an error will be returned to the 'catch' block below.
              console.log("Cached document data:", doc.data());
          
          
             


  if(doc.data().profileName){
    console.log("will set profileName: " + doc.data().profileName);
          
          
    setProfileName(doc.data().profileName)
    } else {
      alert("Please Choose A Profile Name")
    }


    if(doc.data().aboutMe){
      setAboutMe(doc.data().aboutMe)
                    } 
                  

           
          
              if(doc.data().inCallRates){
              setInCallRates(doc.data().inCallRates)
              } else {
              
              }


              if(doc.data().outcallRates){
                setOutCallRates(doc.data().outcallRates)
                              }


                              if(doc.data().phoneNumber){
                                setPhoneNumber(doc.data().phoneNumber)
                                              }
                
                                              if(doc.data().email){
                                                setEmail(doc.data().email)
                                                              }
                                
              

              if(doc.data().specialRates){
                setSpecialNotesAboutRates(doc.data().specialRates)
                              } 


                              if(doc.data().profileImage){
                                setProfileImage(doc.data().profileImage)
                                              } 
                

                                            


                            
          }).catch((error) => {
              console.log("Error getting cached document:", error);
              db.collection("users").doc(auth.currentUser.email).update({"ratesPrivate":true})
          
          });
        }



        useEffect(() => {
          getUsersInfo()
          getSchedules()
               }, []);
             










     useEffect(() => {
  

      getUserRatesPrivatePreference()
      getUsersVideoUrls()
      
           }, []);



const getUserRatesPrivatePreference = () => {




  db.collection("users").doc(auth.currentUser.email).get().then((doc) => {
    // Document was found in the cache. If no cached document exists,
    // an error will be returned to the 'catch' block below.
    console.log("Cached document data:", doc.data());



}).catch((error) => {
    console.log("Error getting cached document:", error);
    db.collection("users").doc(auth.currentUser.email).update({"ratesPrivate":true})

});

  

}




const getUsersVideoUrls = () => {

  setVideoUrls([])

  db.collection("users").doc(auth.currentUser.email).collection("videoUrls").get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());

        console.log(doc.data().url)

        console.log(doc.data().docId)

        const object = {id: doc.id, url:doc.data().url}
        
        setVideoUrls((prevState) => [...prevState, object]); 

    });
});
}

         


const getUsersProfileImages = () => {

  setUrls([])

  if(auth.currentUser){
  if(auth.currentUser.phoneNumber){
    auth.signOut()

    return
}} else {
  window.location.href = '/';
}


  
  db.collection("users").doc(auth.currentUser.email).collection("profileImages").get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());


        console.log(doc.data().profilePictureURL)

        const profileImage = doc.data().profilePictureURL
        


        const object = {id: doc.id, url:doc.data().profilePictureURL, private: doc.data().private,  waterMarked: doc.data().waterMarked}
        
        setUrls((prevState) => [...prevState, object]); 

    });
});
}





































        














    

















  







  









  














    
    






  

    




  
  return (
    <center>
    <div
    style={{ backgroundColor:"black"}}
    >
    




     


<center>
   

{/* <div 
style={{height:"50vh",width:"50vw", 
// backgroundColor:"yellow",
padding:"2vw",
objectFit:"fill",
paddingTop:"100px",
// marginBottom:"40vh",
}}
> */}


    
<p
style={{fontSize:"8vh", color:"white", marginBottom: "1vh", paddingTop:"100px",}}
>
{profileName}
</p>


  <p
        style={{ backgroundColor:"yellow",
        width:"20vw", 
         }}
        >
xo
        </p>

{/* <br/>
<br/>
      <img
      alt="profile Image"
      style={{height:"100%",
       //backgroundColor:"blue"
       objectFit:"contain",
       
       height:"40vh", 
       
       }}
     
       src={profileImage || leopard}
       
        /> */}



       {/* </div> */}


</center>








<div>

</div>






{/*     
      <br />  */}








      <section>
   
    

      <div className="images"
      style={{display:"flex"}}
      >
        {images &&
          images.map((image, index) => {
            return (
              <div key={image.url} className="image"
              //style={{maxHeight:"1vh",maxWidth:"25vw"}}
              >
            
                <img src={image.url} 
                 
                alt="upload" 
                style={{
                    float: "left",
                    objectFit:"contain",
                    width:  "25vw",
                    height: "25vh",
                    backgroundSize: "cover",
                    marginBottom:"2vh",
                borderStyle:"solid",
                
 

    }}
                />


              
              </div>
            );
          })}
      </div>



      <div className="images"
      style={{display:"flex"}}
      >
        {images &&
          images.map((image, index) => {
            return (
              <div key={image.id} className="image"
              style={{
               
                }}
              >
               
               

               
              </div>
            );
          })}
      </div>




    </section>



    
      
    


      <div  style={{display:"flex",  flexWrap:"wrap", marginTop:"10vh"}}>


      {urls.map((url, i) => (
  <div key={i} className="image"
        style={{
          //margin:"1vh",
        // backgroundColor:"yellow"
        position:"relative"
        }}
        >
          <img src={url.url} 
          // height="200" 
          alt="upload" 

          style={{
            objectFit:"contain",
width:  "48vw",
// height: "48.5vh",
backgroundSize: "cover",
borderStyle:"solid",
          //borderColor:"white",
          padding:"1vh",
          }}
          />

{url.waterMarked && url.waterMarked === true?( 
   <div

   style={{
    height:"1vh", 
   //position:"absolute",
    top:"50%", left: "25%",
    zIndex:2,
    position:"absolute"
    
    }}
   >
   
  
   <img src={leopardClear} 
        
          alt="leopardClear" 

          style={{
            height:"30vh", 

backgroundSize: "cover",
          padding:"1vh",
          }}
          />

   </div>
):(
  <div>
   no water mark
   </div>
)}






        </div>
        
))}
</div>

      <br/>
    <br/>


     



 

<center>

<div className="images"
      style={{display:"flex",  flexWrap:"wrap"}}
      >


{ videoUrls.map((url, i) => (
<div
style={{
  //backgroundColor:"red", 
width:"49vw"}}
>
  <ReactPlayer
 
 url={url.url}
 controls={true}
 style={{padding:"1vh",width:"49vw",borderstyle:"solid", borderColor:"white"}}
 >
 
 </ReactPlayer>

 </div>

))}

</div>





{aboutMe && aboutMe !== undefined? (
<p
style={{backgroundColor:"yellow",fontSize:"2.5vh", padding:"5vh"}}
>
 <br/>
  <br/>
  ABOUT ME 
  <br/>
  <br/>
  {aboutMe}
  <br/>
  <br/>
  <br/>
  <br/>
</p>
):(
  null
)
}





<br/>


   

        


    <div


style={{display:"flex", }}
>
<div
style={{display:"flex", borderStyle:"solid", borderColor:"white"}}
>
<div
style={{width:"49.6vw", marginBottom:"2vh"}}
>
<p
style={{fontSize:"5vh", color:"white"}}
>
INCALL
</p>

          <ul
          style={{backgroundColor:"black"}}
          >
            {inCallRates && inCallRates.split(',').map((el,i) => <li
            style={{fontSize:"3vh", color:"white", marginTop:"2vh"}}
             key={i}>{el}</li> )}
          </ul>




</div>

</div>


<div
style={{display:"flex", flexWrap:"wrap", borderStyle:"solid", borderColor:"white"}}
>
<div
style={{width:"50vw"}}
>
<p
style={{fontSize:"5vh", color:"white"}}
>
OUTCALL
</p>

<ul
          style={{backgroundColor:"black"}}
          >
            {outcallRates && outcallRates.split(',').map((el,i) => <li
            style={{fontSize:"3vh", color:"white", marginTop:"2vh"}}
             key={i}>{el}</li> )}
          </ul>

          


</div>

</div>

</div>

<div
// style={{width:"49vw"}}
style={{ borderStyle:"solid", borderColor:"white",fontSize:"3vh", color:"white" }}

>
<p
style={{fontSize:"5vh", color:"white" , marginTop:"5vh"}}
>
</p>

{specialRates}

<br/>
<br/>


</div>


<div>


</div> 
   


      <br/>

    <br/>


     
 


    <br/>


      <br />


<p
style={{fontSize:"5vh", color:"white", marginBottom: "vh"}}
>
TEXT ONLY: {phoneNumber}
</p>

<br />
<br />
<br />


<p
style={{fontSize:"5vh", color:"white", marginBottom: "vh"}}
>
Email: {email}
</p>

<br />

{schedules && schedules.length > 0 ? (
<p
style={{fontSize:"10vh", color:"white", borderStyle:"solid", borderColor:"white", width:"60vw"}}
>Schedules</p>

):(
  null
)}

<div className="images"
      style={{display:"flex",
      // backgroundColor:"yellow",
      flexWrap:"wrap",
      paddingBottom: "50vh"}}
      >




{schedules &&
          schedules.map((schedule, index) => {

            return (

<LeopardsUsersCard
getSchedules={getSchedules}
city = {schedule.city}
profileImage = {schedule.profileImage}
startDate = {schedule.startDate}
endDate = {schedule.endDate}
startDateString = {schedule.startDateString}
endDateString = {schedule.endDateString}
email = {schedule.email}
profileName = {schedule.profileName}
gender = {schedule.gender}
id = {schedule.id}
style={{height:"25vh"}}
viewProfile={false}
aboutMe={schedule.aboutMe}

/>

            );
          })}
          </div>

</center>


    






    </div>








     


    </center>
  )

}


// put current country flag and original flag on this page , 



