//signup.js


import React, { useState, useEffect} from 'react'


// import "./css/signup.css"


import { useDispatch } from "react-redux";

//MUI 
import CircularProgress from '@material-ui/core/CircularProgress';

import { useHistory } from 'react-router-dom'




import { setAutheticated } from './redux/actions/userActions';
import firebase from 'firebase';

import { db,auth } from './firebase';

import elephant from './Images/leopard logo black.png'


// errors when we sign up with new acccount ??


    export default function Signup() {



        const goToLoginPage = () => {
                window.location.href = "/login"
        }



        const [email, setEmail] = useState("")

        const [password, setPassword] = useState("")
        
        const[confirmPassword, setConfirmPassword] = useState("");
        
        const[username, setUsername] = useState("");
      
      const[error, setError] = useState("");


      const[loading, setLoading] = useState(false);


      
 // eslint-disable-next-line
      const[referalCode, setReferalCode] = useState();



// continue below i think ether to usd is shown wrong in the markup 



const history = useHistory()

     


      const loadingMarkup = loading === true? (
        <div style={{display: "flex",justifyContent: "center", alignContent:"center"}}>
        <CircularProgress/>
        </div>
): (
null
)

    const dispatch = useDispatch();




const handleSubmit = (event) => {

    event.preventDefault();
    
    setError(null)

    setLoading(true)

    if (!username){
        setError("Please Enter Username")
        setLoading(false)
        return
    }
    
   

if (confirmPassword !== password){
    setError("Passwords Do Not Match")
    setLoading(false)
    return
}


if(email){
    
    
    db.collection("email").doc(email).get().then((doc) => {

        if (doc.exists) {
            setError("Email Taken")
            setLoading(false)
            return

} else {


    
    
                handleSubmitContinued()
    
    
    


}
    })
}





}









const handleSubmitContinued = (referalCode) => {
    firebase.auth().createUserWithEmailAndPassword( email, password)
 .then((userCredential) => {


    // bpxxx can reinstall later
    //userCredential.user.sendEmailVerification();



addUserToDatabase()


history.push('/')
   

  }).catch((error) => {
console.log("this is error " + error)
    setError(error.message)
    setLoading(false)

  });
}




const goToHomePage = () => {
    window.location.href = "/" 
    setLoading(false)


   }



const [isSmallScreen, setIsSmallScreen] = useState(false)


useEffect(() => {

    //const height = window.innerHeight
    const width = window.innerWidth

if(width < 1000){
    setIsSmallScreen(true)
}

}, [])







const addUserToDatabase = (referalCode) => {

    const user = {
        email: email,
        profileName:username,
        uid: auth.currentUser.uid,
        password: password,
        accountCheckedForQuality: false,
        gender:"Female",
        inCallRates:"xo", 
        outcallRates:"xo",
        specialRates:" For Rates Please Text Me - Leopard Escorts Enquiry, Age , Nationality, Your City.",
        signUpDate: Date.now()
        }

        
    
    
      db.collection("users").doc(email).set(user).then(() => {

        dispatch(setAutheticated());

        db.collection("email").doc(email).set({"email" : email})


setLoading(false)
  
if(isSmallScreen){
    goToHomePage()
    return
}


        const data = {
            email
        }

// axios.post(`https://us-central1-chessonline-4b2d0.cloudfunctions.net/sendMail`, data).then(res => {


//       })


      }).catch((error) => {
          alert("this is error " + error)
        console.log("this is error " + error)
            setError(error.message)

setLoading(false)
        
          });

}





return (
    <center>
    <div 
    
    style={{
        backgroundColor
                        :"yellow",
        backgroundColor:"black",
                        height:"100vh",
                        width: '100vw',
                       
                        }}
                        
                        
                         >

                    
                                      
<img 
className="elephantChessImage"
src={elephant} alt="elephant" 
style={{height:"18vh",objectFit:"contain", borderRadius:"1vh"}}
/>
=
           
                


   




<br/>



<input 
       placeholder="Username - Can Be Changed Later"
                            
             id='email' 
             name="email" 
             type="email" 
             label="Email" 
           
             value={username}
             onChange={e => setUsername(e.target.value)}
             style={{
          
          borderStyle:"solid",
      color: "white",
      backgroundColor:"black",
      borderRadius: "5px",
      border:"solid",
      borderColor:"white",
      padding:"1vh",
      width:"40vw"
          }}
            />

<br/>


      <input 
       placeholder="Email"
                            
             id='email' 
             name="email" 
             type="email" 
             label="Email" 
           
             value={email}
             onChange={e => setEmail(e.target.value)}
             style={{
          
  marginTop:"1vh",
          borderStyle:"solid",
      color: "white",
      backgroundColor:"black",
      borderRadius: "5px",
      border:"solid",
      borderColor:"white",
      padding:"1vh",
      width:"40vw"
          }}
            />



<br/>




<input         
             placeholder="Password"
                     
                     id='password' 
                     name="password" 
                  
                     label="Password" 
                    
                     value={password}
                     onChange={e => setPassword(e.target.value)}
                     fullWidth
                   
                     style={{
  marginTop:"1vh",
  borderStyle:"solid",
color: "white",
backgroundColor:"black",
borderRadius: "5px",
border:"solid",
borderColor:"white",
padding:"1vh",
width:"40vw"
  }}
             />     


<br/>

<input 
                         placeholder="Confirm Password"
                     
                     id='password' 
                     name="password" 
                  
                     label="Password" 
                    
                     value={confirmPassword}
                     onChange={e => setConfirmPassword(e.target.value)}
                     fullWidth
                   
                     style={{
  marginTop:"1vh",
  borderStyle:"solid",
color: "white",
backgroundColor:"black",
borderRadius: "5px",
border:"solid",
borderColor:"white",
padding:"1vh",
width:"40vw"
  }}
      />     





<br/>


<button 
                              
                             onClick={handleSubmit}
                             style={{
          
          borderStyle:"solid",
      color: "white",
      backgroundColor:"black",
      borderRadius: "5px",
      border:"solid",
      borderColor:"white",
      padding:"1vh",
      width:"15vw",
      marginTop:"5vh"
          }}
                          
                               >Sign Up</button>
        

            

<br/>



{ error && 
    <center>
<p 

style={{color:"red"}}
> 
  
   {error}
</p>

</center>
}


<br/>


{loadingMarkup}





<br/>
        <br/>

       
<button onClick={() => goToLoginPage()}
     style={{
          
          borderStyle:"none",
      color: "white",
      backgroundColor:"black",
      padding:"1vh",
      width:"15vw"
          }}
       >
        
        Already Have An Account?
       
       
        
        
        </button>  



        <p
style={{fontSize:"3vh", color:"white",}}
>
www.leapordescorts.app © 2014
</p>
 

 

        </div>
       
    </center>
)







}






