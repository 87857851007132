






import React, { useEffect, useState } from 'react'

import LeopardsUsersCard from './LeopardsUsersCard';

import { db, auth } from "./firebase";  


export default function PendingApprovalsPage() {




const [users, setUsers] = useState([]);


const getSchedules = () => {
  alert("this is not set up on this page")
}




useEffect(() => {


getAllUsers()
 
          }, [])
 



          const getAllUsers = () => {


            db.collection("awaitingApproval").get()
            .then((querySnapshot) => {
        

              if(querySnapshot.size <= 0){
                alert("no pending approvals")
              }
              
                querySnapshot.forEach((doc) => {

                
                const object = {id: doc.id, profileImage:doc.data().profileImage,email:doc.data().email}
          
          
                setUsers((prevState) => [...prevState, object]); 
              })
              })
          }
        


 
  return (

  

<center>
    <div
    style={{ backgroundColor:"black", paddingTop:"10vh"}}
    >
    
    getAllUsersPage
    

    <div className="images"
      style={{display:"flex",
      backgroundColor:"black",
      flexWrap:"wrap"
      }}
      >
    

    {users &&
          users.map((schedule, index) => {
            
    
       
            return (
<LeopardsUsersCard
getSchedules={getSchedules}
city = {schedule.city}
profileImage = {schedule.profileImage}
startDate = {schedule.startDate}
endDate = {schedule.endDate}
startDateString = {schedule.startDateString}
endDateString = {schedule.endDateString}
email = {schedule.email}
profileName = {schedule.profileName}
gender = {schedule.gender}
id = {schedule.id}
viewProfile={true}
style={{height:"25vh"}}
approvalCard={true}

/>
            );
          })}

</div>
    </div>
    </center>
  )
}




