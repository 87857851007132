
//userReducer.js

// import store from '../store';
import {  SET_AUTHENTICATED,SET_UNAUTHENTICATED, LOADING_USER,SET_USER, GET_REALTIME_CONVERSATIONS, UPDATE_USER, UPDATE_USER_INFO, SET_LOADING_FALSE ,DEACTIVATE_ACCOUNT,ACTIVATE_ACCOUNT,
     SET_WHITE_CLOCK_TIME ,SET_BLACK_CLOCK_TIME, SET_CLOCK_VALUES_AND_TIMESTAMP,SET_PLAYERS_COLORS, SET_PLAYERS_RATING, SET_PLAYERS_USERNAME,
      SET_KNOWN_WALLET_ADDRESS,SET_FIRESTORE_SERVER_TIMESTAMP,SET_BOARD,SET_PROFILE_IMAGE,
    SET_COUNTRY_CODE, SET_ORIGINAL_COUNTRY_CODE,
    SET_WIN_STREAK, SET_WIN_STREAK_VS_ROBOT ,GET_REALTIME_MESSAGES ,
    SET_TOTAL_LOSES, SET_TOTAL_WINS,SET_PLAYERS_THREE_MINUTE_RATING,SET_PLAYERS_FIVE_MINUTE_RATING

    } from "../types"




const initialState = {
authenticated: false,
loading: false,
credentials: {},
jobPosts:[],
usersJobs:[],
};

export default function userReducer(state = initialState,action) {



   
    



    switch(action.type){







case SET_PLAYERS_THREE_MINUTE_RATING:
    return{
        ...state,
        threeMinuteRating: action.payload
    }


    case SET_PLAYERS_FIVE_MINUTE_RATING:
    return{
        ...state,
        fiveMinuteRating: action.payload
    }



 

case SET_TOTAL_WINS:
    return{
        ...state,
        totalWins: action.payload
    }

    case SET_TOTAL_LOSES:
    return{
        ...state,
        totalLoses: action.payload
        
    }


case SET_WIN_STREAK_VS_ROBOT:
    return{
        ...state,
        winStreakVsRobot: action.payload
    }

        

        case SET_WIN_STREAK:
          
            return{
                ...state,
                winStreak: action.payload
            }

        case SET_PROFILE_IMAGE:
            return{
                ...state,
                profileImage: action.payload
            }

        



            case SET_ORIGINAL_COUNTRY_CODE:
                
                return{
                    ...state,
                    originalCountryCode: action.payload
                }
    



        case SET_COUNTRY_CODE:
            return{
                ...state,
                countryCode: action.payload
            }

        

        case SET_BOARD:
            
            return{
                ...state,
                board: action.payload
            }

        case SET_FIRESTORE_SERVER_TIMESTAMP:
            return{
                ...state,
                FirestoreServerTimeStampForLastMove: action.payload
            }
        



        
    
    
        
        case SET_KNOWN_WALLET_ADDRESS:
    return{
        ...state,
        knownWalletAddress: action.payload
    }




    

case SET_PLAYERS_USERNAME:
    return{
        ...state,
        username: action.payload
    }




        

        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: true
            };

case SET_PLAYERS_RATING: 

return {
    ...state,
    rating : action.payload
}






            case SET_PLAYERS_COLORS:
            return {
                ...state,
                ...action.payload
            }

           

            case  SET_CLOCK_VALUES_AND_TIMESTAMP:
  
                return {
                    ...state,
                   ...action.payload
                };
    


            

            case SET_BLACK_CLOCK_TIME: 
            return{
                ...state,
                blackClockTime:action.payload
            }

            case SET_WHITE_CLOCK_TIME: 
            return{
                ...state,
                whiteClockTime:action.payload
            }

            case SET_UNAUTHENTICATED:
                console.log("set unauthenticated called")
                return initialState;

              

                        case LOADING_USER:
                            return{
                                ...state,
                                loading: true
                            };
   

    case SET_USER:
        return{
            
            authenticated: true,
            loading: false,
          ...action.payload,

          
        };

       
        case  GET_REALTIME_CONVERSATIONS:
        return{
            authenticated: true,
            loading: false,
          ...action.payload,
        };
        


case GET_REALTIME_MESSAGES:
    return {
        ...state,
        messages: action.payload
    };
    
    





case UPDATE_USER:
    return{
        authenticated: true,
        loading: false,
        credentials: action.payload,
    };



    case UPDATE_USER_INFO:
    return{
        
        authenticated: true,
        loading: false,
        credentials: action.payload,

    };
 

    case  SET_LOADING_FALSE:
        return{
            authenticated: false,
            loading: false,
            credentials: {},
jobPosts:[],
usersJobs:[],
        };




case DEACTIVATE_ACCOUNT:
    return{
        ...state,
        authenticated: true,
        loading: false,
        accountExpired: true,
    };
        


case ACTIVATE_ACCOUNT:
    return{
        ...state,
        authenticated: true,
        loading: false,
        accountExpired: false,
    };
        




                    default:
                        return state;
    }
}




