///ScheduleCitySelectionMenu
/////BaseCitySelectionMenu
//HomeSearchCitySelectionMenu


//localStorage.setItem("HomeSearchCity",options[index])
//localStorage.setItem("BaseCity",options[index])
//localStorage.setItem("ScheduleCity",options[index])

import React, { useEffect } from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';





import "./css/Home.css"





const options = [
  "Adelaide",
  "Brisbane",
  "Canberra",
  "Darwin",
  "GoldCoast",
  "Hobart",
  "Melbourne",
  "Perth",
  "Sunshine Coast",
  "Sydney",
];






export default function HomeSearchCitySelectionMenu(props) {

   


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);



  const handleClickListItem = (event) => {

    
    
    setAnchorEl(event.currentTarget);
  
   
  };



  useEffect(() => {
    const city = localStorage.getItem("HomeSearchCity")

    if(city === null){
      localStorage.setItem("HomeSearchCity","Melbourne")
    }
    
         }, []);
       


  const handleMenuItemClick = (event, index) => {
    
    localStorage.setItem("HomeSearchCity",options[index])

    props.func(options[index]);

    setSelectedIndex(index);
    setAnchorEl(null);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div 
    
    >

  
      <List
      className='listItemButton'


      component="nav" aria-label="Device settings">
        <ListItem
         className='listItemButton'
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          onClick={handleClickListItem}
        >
          <ListItemText 
          className='listItemText'
          primary={ localStorage.getItem("HomeSearchCity")} 
          

          />
         
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{
          // backgroundColor: "purple",
          // marginTop:"99",
        
        }}
      >
        {options.map((option, index) => (
          <MenuItem
         
style={{
          width:"100vw",
          fontSize:"3vh"
        }}
            key={option}
            // disabled={index === 0}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
          


             {`${option}`} 
          
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

 