import React from 'react'
import { db, auth } from "./firebase";  


import { useHistory } from 'react-router-dom'



// import './css/leopardsUsersCard.css'






//import classes from './stylesheet.css'
import './css/leopardsUsersCard.css' 



export default function LeopardsUsersCard(props) {

    const history = useHistory()

const viewProfile = () => {



    history.push({
        pathname: `/ProfilePage`,
        state: { email : props.email }
      })

}



const Approve = () => {

 db.collection("users").doc(props.email).update({"accountCheckedForQuality":true}).then((doc) => {
    alert("Application Approved")
   }).then(() => {
    db.collection("users").doc(props.email).update({"accountCheckedForQuality":true, "submittedForApproval":false})
   }).catch((error) => {
   alert("Error " + error)            
   }); 
   
  db.collection("awaitingApproval").doc(props.email).delete().then((doc) => {
    alert("Awating Approval delete")
   }).catch((error) => {
   alert("Error " + error)                 
   });
}

const deleteSchedule = () => {

  if (auth.currentUser.email === undefined){
    return
  }

if(auth.currentUser.email){

    db.collection("users").doc(props.email).collection("schedule").doc(props.id).delete().then(()=>{
        console.log("schedules deleted from users section successfully")
    }).catch((error) =>{
        alert("error deleteing schedules 23$@#$R@#  " + error)
    })

  }

    db.collection("schedules").doc(props.city).collection(props.gender).doc(props.id).delete().then(()=>{
        console.log("schedules deleted successfully")
    }).catch((error) =>{
        alert("error deleteing schedules 23$443@#$R@#  " + error)
    })

  

props.getSchedules()

}




  return (

    <div
className={"LeopardUserCardMainDiv"}
    //  className="LeopardUserCardMainDiv"
    // style={{height:"53vh",width:"26vw", borderStyle:"solid", borderColor:"white",
    // position:"relative",
    // borderRadius:"8px", marginBottom:"2vh",
    // }}
    >
 
    <p
className={"ProfileNameTitle"}
  //   style={{backgroundColor:"black", 
  //   // backgroundColor:"purple", 
  //   fontSize:'3vh', color:"white",
  // width: "100%",
  //  height:"3vh",
  //  fontColor:"white",
  //  fontWeight: "bold",
  //  marginTop:"1.5vh"
  //   }}
    >
    {props.profileName?? "Incognito"}
    </p>
 

    <img
className={"ProfileImage"}
  //    style={{
  //       height:"30vh",width:"24.75vw", 
  //         borderStyle:"solid",
  //  borderColor:"white",
  //        objectFit:"contain"}}
    src={props.profileImage}
    >

    </img>


    <p
     style={{backgroundColor:"black", 
    fontSize:'2vh', color:"white",
  width: "100%",
    }}
    >
    
    {props.city}

  </p>
 

     
 
    <div>
           

    {auth.currentUser && auth.currentUser.email === "benpalmer661@icloud.com" && props.submittedForApproval === true?(


<button


style={{height:"3vh", width:"30%",
// marginTop:"1vh",
backgroundColor:"black",
color:"white",
fontSize:"1.5vh",
}


}


onClick={() => Approve()}
>
Waiting Approval - approve?
</button>  

):(
<div></div>
)
}


{props.viewProfile && props.viewProfile === true?(


          <button


     style={{height:"3vh", width:"30%",
     // marginTop:"1vh",
     backgroundColor:"black",
     color:"white",
fontSize:"1.5vh",
}
  
     
     }

 
     onClick={() => viewProfile()}
     >
View Profile 
     </button>  
     
      ):(
<div></div>
     )
}

  
      {auth.currentUser && auth.currentUser.email === props.email && props.isBaseCityCard !== true? ( 
  
      <button 
             onClick={() => deleteSchedule()}
         
            style={{height:"3vh", width:"30%", 
          // marginTop:"1vh",
     backgroundColor:"black",
     color:"white",
     fontSize:"1.5vh",

         }}>
           Delete
         </button>  

 ):(
 null
)}



{props.startDateString ?(
  <p
     style={{
      backgroundColor:"yellow", 
      // backgroundColor:"black", 
    fontSize:'2vh', 
    color:"black",
    // color:"white",
  width: "100%",
    }}
    >

  {props.startDateString} - {props.endDateString} 

  </p>

):(

<div>
</div>

)}
     


     </div>
      




    
    
    </div>




  )
}


{/* <p
style={{backgroundColor:"black",  */}
//opacity:"0.5", 
// fontSize:'2vh', color:"white",
//     position:"absolute",
//     left: 0,
//   right: 0,
//   marginLeft: "auto",
//   marginRight: "auto",
// width: "25vw",
// borderStyle:"solid",
// borderColor:"white"
// }}

// >Romina
// </p>