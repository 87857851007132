///ScheduleCitySelectionMenu
/////BaseCitySelectionMenu
//HomeSearchCitySelectionMenu


//localStorage.setItem("HomeSearchCity",options[index])
//localStorage.setItem("BaseCity",options[index])
//localStorage.setItem("ScheduleCity",options[index])

import React, { useEffect } from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';














export default function ScheduleCitySelectionMenu(props) {




  const options = [
    "Adelaide",
    "Brisbane",
    "Canberra",
    "Darwin",
    "GoldCoast",
    "Hobart",
    "Melbourne",
    "Perth",
    "Sunshine Coast",
    "Sydney",
  ];
  
  

useEffect(() => {
  const city = localStorage.getItem("ScheduleCity")

  if(city === null){
    localStorage.setItem("ScheduleCity","Melbourne")
  }
  
       }, []);
   


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);



  const handleClickListItem = (event) => {

    
    
    setAnchorEl(event.currentTarget);
  
   
  };






  const handleMenuItemClick = (event, index) => {
    
    localStorage.setItem("ScheduleCity",options[index])

    props.func(options[index]);

    setSelectedIndex(index);
    setAnchorEl(null);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div 
    
    >

  
      <List
className="button is-primary"
       style={{
        height: "5vh",
        width:"20vw",
        border: "1px solid #dfe1e5",
        borderRadius: "4px",
        boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
        hoverBackgroundColor: "#eee",
        fontSize: "2vh",
        fontFamily: "Arial",
        iconColor: "grey",
        lineColor: "rgb(232, 234, 237)",
        placeholderColor: "grey",
        padding:"0px",
       
        left:"0",
        color:"white",
backgroundColor:"black",
textAlign: "center"
        
       }}
      component="nav" aria-label="Device settings">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          onClick={handleClickListItem}
        >
          <ListItemText 
          primary={localStorage.getItem("ScheduleCity")} 
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{
          // backgroundColor: "black",
          // marginTop:"99",
        
        }}
      >
        {options.map((option, index) => (
          <MenuItem
style={{
          width:"100vw",
          fontSize:"3vh"
        }}
            key={option}
            // disabled={index === 0}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
          


             {`${option}`} 
          
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

 