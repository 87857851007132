


//navbar.js
import React, { Fragment,useState,useEffect } from 'react'


import { Link } from 'react-router-dom';
import MyButton from './util/MyButton'

import { auth,db } from './firebase'
import { setUnAutheticated ,setPlayersUsername,setProfileImage } from './redux/actions/userActions';

import { useHistory } from 'react-router-dom'


///npm install @material-ui/core
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase'

import leopardLogoBlack from './Images/leopard logo black.png'


import "./css/navbar.css"


export default function Navbar() {


const dispatch = useDispatch();



const history = useHistory()

    const signout = () => {
        firebase.auth().signOut().then(() => {
dispatch(setUnAutheticated())
      window.location.href = '/login';
          }).catch((error) => {
            // An error happened.
          });
    }

  










const [image, setImage] = useState()






const [currentUsersEmail, setCurrentUsersEmail] = useState("")






















    
   
    

    





useEffect(() => {


    const { currentUser } = auth

    
    if (currentUser) {

    
        setCurrentUsersEmail(auth.currentUser.email)

    db.collection("users").doc(auth.currentUser.email).get().then((doc) => {

        if (doc.exists) {


               const profileImageUrl = doc.data().profileImage

        
if(profileImageUrl) {
    dispatch(setProfileImage(profileImageUrl));
    setImage(profileImageUrl)
}









         

        







        } 
    })
    }
 // eslint-disable-next-line
}, [])











const authenticated = useSelector(state => state.user.authenticated)


const [notAvailableOnSmallScreens, setNotAvailableOnSmallScreens] = useState(false)


useEffect(() => {

    const width = window.innerWidth

if(width < 900){
    setNotAvailableOnSmallScreens(true)
}

}, [])





    
        return (
            <AppBar
            style={{backgroundColor:"black"}}
            >
            <Toolbar 
            style={{
                //margin:"auto",
            backgroundColor:"black",
            height:"10vh"}}
            >


{authenticated ? ( 

 notAvailableOnSmallScreens === false ? (
<Fragment>

<div
style={{display:"flex",

fontSize:"2.5vh",
}}
>



<img
  style={{height:'10vh', width:"10vw", objectFit:"scale-down",
   padding:"1vh", 
  }}
  src={leopardLogoBlack}
  >

  </img>

  <img
  style={{height:'10vh', width:"10vw", objectFit:"scale-down",
   padding:"1vh", 
  }}
  src={image}
  >

  </img>


 


</div>

{ auth.currentUser && auth.currentUser.email === "benpalmer661@icloud.com" ? (
    <MyButton
tip="Real Play"
onClick={
    () => {     history.push('/PendingApprovalsPage')
    }}
>
 <p
 style={{
     color:"black",
    marginLeft:"5vw",
     color:"white",
     fontSize:"2.5vh"
 }}
 >PAP</p>
</MyButton>
):(
null
)
}



 

{ auth.currentUser && auth.currentUser.email === "benpalmer661@icloud.com" ? (
    <MyButton
tip="Real Play"
onClick={
    () => {     history.push('/CreateAccountPage')
    }}
>
 <p
 style={{
     color:"black",
    marginLeft:"5vw",
     color:"white",
     fontSize:"2.5vh"
 }}
 >CreateAccountPage</p>
</MyButton>
):(
null
)
}

{ auth.currentUser && auth.currentUser.email === "benpalmer661@icloud.com" ? (
    <MyButton
tip="Real Play"
onClick={
    () => {     history.push('/AllUsersPage')
    }}
>
 <p
 style={{
     color:"black",
    marginLeft:"5vw",
     color:"white",
     fontSize:"2.5vh"
 }}
 >All Users</p>
</MyButton>
):(
null
)
}



<MyButton
tip="Real Play"
onClick={
    () => {     history.push('/')
    }}
>
 <p
 style={{
     color:"black",
    marginLeft:"5vw",
     color:"white",
     fontSize:"2.5vh"
 }}
 >Home</p>
</MyButton>





<MyButton
tip="Transactions Page"
onClick={() => {history.push('/EditProfilePage')}}
>
 <p
  style={{
    color:"white",
    fontSize:"2.5vh",
 }}
 >Edit Profile</p>
</MyButton>








<MyButton
tip="About"
onClick={() => {history.push('/MyProfilePage')}}
>
   <p
  style={{
    color:"white",
    fontSize:"2.5vh",
 }}
 > 
 My Profile Page  </p>
</MyButton>




<br/>


<MyButton
tip="Logout"
onClick={signout}
>
   <p
  style={{
    color:"white",
    fontSize:"2.5vh"
 }}
 > Log Out</p>
</MyButton>





</Fragment>
) : (
<Fragment

style={{
    color:"white",
    fontSize:"2.5vh",
 backgroundColor:"yellow",

 }}
>


<MyButton
tip="Logout"
onClick={signout}
>
   <p
  style={{
     color:"black",
     fontSize:"2.5vh"
 }}
 > Log Out</p>
</MyButton>




</Fragment>
)

) : (

<Fragment> 

<Button color="inherit" component={Link} to="/"
style={{
    fontSize:"2.5vh",
color:"white"  , margin:"5px"}}

> Home</Button>


<Button color="inherit" component={Link} to="/login"

style={{
    fontSize:"2.5vh",
    color:"white"   , margin:"5px"}}

> Login</Button>



<Button color="inherit" component={Link} to="/signup"
style={{
    fontSize:"2.5vh",
    color:"white"   , margin:"5px"}}

> SignUp</Button>







</Fragment> 

) }
            </Toolbar>   
         </AppBar>
        )
    }
    
    























