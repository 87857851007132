//home.js



// to deploy app watch this video
// https://www.youtube.com/watch?v=IDHfvpsYShs
//steps 

//step one
//npm run build - this create a build folder of static files that work 
//om most if not all browsers

//step two
//firebase  init
//select  hosting
// what do you want to use as your public directory? 
//  Enter "build"
//configure as a single page app? YES
//File build/index.html already exists. Overwrite? No

//wait until complete should be fast
//then enter in the console "firebase deploy"
//select no to deleting functions



// continue with the homesearchcityselection menu to work on small screen and match the search button 

//to do 


///for music to put wav on logic use https://convertio.co



///  continue with report user page, we now have profile name added just make it look good and update database 
//propertly


//free jpeg convert - https://png2jpg.com/


//anywhere where people can hack data we need to stop it by setting up only once in given time period
/// can do 3 times max a minute then 100x max an hour or something then ban ip address?

//to swap react styles to css use https://codepen.io/allenchum/full/QWLRRJR

// make leopard user card 2 and the image fills the whole screen and is a button , you click to view profile, 
//it doesn't say view profile, then have a yellow title with their name.


//all images put leopard escort logo with loading ,  until image fully loads ? 

//set up screens for mobile with have started with home and home.css , just make one card per screen , then move on
//to profile with one picture per screen etc,

//make a thing if benpalmer661@icloud.com you can see non approved accounts in searchs though if not , the non approved 
//accounts do get show , make title only seeing non approved accoutn because benpalmer661@icloud.com, also have the same for if
//the  email has  been verified or not.

///set up , base , home search and schedule city selection menu, make sure there is a value , as in ?? brisbane has been set
//if nothing else , and you can have localstorage at the top of options if that works for keeping the last serached value

//we show the delete button on leopardprofile and it should only be shown on schedudle ??

//need to check accountCheckedForQuality before you can post schedule 
///  and also accountCheckedForQuality for  each user if not true dont show


//have to do the thing so people cant steal code of inspect

//make a submit form so users with email can submit to get a number from ecorts , 
//have this ready to go.


//set up top add, so people who pay can be up the top 

//myprofile page if main image picture is too tall it goes under the profile images that 
//are mapped out

//make better water mark image

//make report user buttons

//make water mark image appear on main profile images

// we  have began to try water market images uploaded to ref imagesWaterMark so when real people upload they 
// are water marked when diplayed.


// show in base city needs coding and wording rt

// when  person add their own image it has a leopard water mark

// when profile picture does not take up whole screen we have an image behind it that does , like on whatsapp

//make the show in my city yes or no  thing better

// resest password page

//past escorts , could probably get rid of them all together, or get rid of them if they are base city escorts only 
//this way people can  follow them etc

// all escorts page

//if no schedules in profile dont show the schedules title

//if says show basecity then show users based location in profile


import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';



import React, { useEffect, useState } from 'react'

import { db, auth } from "./firebase";  

import "react-datepicker/dist/react-datepicker.css";

import LeopardsUsersCard from './LeopardsUsersCard';

import moment from 'moment';
import LeopardGenderSelectionMenu from './LeopardGenderSelectionMenu';


import "./css/Home.css"
import HomeSearchCitySelectionMenu from './HomeSearchCitySelectionMenu';

  export default function Home(props) {


var HomeSearchCity = localStorage.getItem("HomeSearchCity")?? "Sydney"

const [city, setCity] = useState(HomeSearchCity);

const [gender, setGender] = useState("Female");

const [upcomingSchedules, setUpcomingSchedules] = useState([]);

const [pastSchedules, setPastSchedules] = useState([]);

const [schedules, setSchedules] = useState([]);



const uniqueSchedules = Array.from(new Set(schedules.map(a => a.email)))
 .map(email => {
   return schedules.find(a => a.email === email)
 })



const uniquePastSchedules = Array.from(new Set(pastSchedules.map(a => a.email)))
.map(email => {
  return pastSchedules.find(a => a.email === email)
})





useEffect(() => {

  getSchedules()
  getBaseCitySchedules()
     }, [gender]);
   
       
const getUsersAndSchedules = () => {
  getSchedules()
  getBaseCitySchedules()
}




const getSchedules = () => {


setSchedules([])

setUpcomingSchedules([])


setPastSchedules([])




  db.collection("schedules").doc(city).collection(gender).get()
  .then((querySnapshot) => {


      querySnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());



        //   if(doc.data().email === null){
        //     alert(`doc id ${doc.id} with city ${city} has null email`)
        // return
        // } else {
        //     alert(`doc id ${doc.id} - no email  email ${doc.data().email}`)
        //     return
        // }


const wasTheEndDateYesterdayOrBefore = moment(Date.now()).subtract(1, 'day').isAfter(moment(doc.data().endDate.toDate()))

const wasTheEndDateWasMoreThanAWeekAgo = moment(Date.now()).subtract(7, 'day').isAfter(moment(doc.data().endDate.toDate()))

if(wasTheEndDateWasMoreThanAWeekAgo === true){

  if(auth.currentUser && auth.currentUser.email  === "benpalmer661@icloud.com"){
    alert(` benpalmer661@icloud.com only alert - end date was more then a week ago so we will not include this post and delete it - doc.id is: ${doc.id}`)
    }

     
if(auth.currentUser){
  db.collection("users").doc(doc.data().email).collection("schedule").doc(doc.id).delete()
  .then(() =>{

    if(auth.currentUser && auth.currentUser.email  === "benpalmer661@icloud.com"){
      alert("benpalmer661@icloud.com only alert - old document deleted succesfully will delete the next one")
    }

  }).catch((err) => {
  alert("Error 234234234: " + err)
  })
}


  db.collection("schedules").doc(city).collection(gender).doc(doc.id).delete().then(() =>{
    
    if(auth.currentUser && auth.currentUser.email  === "benpalmer661@icloud.com"){
      alert("benpalmer661@icloud.com only alert - old document deleted succesfully will delete the next one")
      }
   
  }).catch((error) => {
    alert("345133445 error "+ error)
  });


}




if(wasTheEndDateYesterdayOrBefore === true){


setPastSchedules([])

console.log("will go to past schedules ")


            const object = {id: doc.id, profileImage:doc.data().profileImage,
              email:doc.data().email, 
              startDate:doc.data().startDate
              , endDate:doc.data().endDate, city:doc.data().city, profileName: doc.data().profileName, startDateString: doc.data().startDateString,
              endDateString: doc.data().endDateString, gender: doc.data().gender,
              submittedForApproval: doc.data().submittedForApproval,
            } 

     
            setPastSchedules((prevState) => [...prevState, object]); 


  return

}



          const isTheStartDateTomorrowOrInTheFuture =  moment(doc.data().startDate.toDate()).isAfter(moment(Date.now()))



          if(isTheStartDateTomorrowOrInTheFuture === true){

            console.log("will add to upcoming schedules")

            const object = {id: doc.id, profileImage:doc.data().profileImage,email:doc.data().email, startDate:doc.data().startDate
              , endDate:doc.data().endDate, city:doc.data().city, profileName: doc.data().profileName, startDateString: doc.data().startDateString,
              endDateString: doc.data().endDateString, gender: doc.data().gender,
              submittedForApproval: doc.data().submittedForApproval,
            } 
     
            setUpcomingSchedules((prevState) => [...prevState, object]); 
  
            return
          }


          console.log("made it to here will -  add to current schedules")

          const object = {id: doc.id, profileImage:doc.data().profileImage,email:doc.data().email, startDate:doc.data().startDate
            , endDate:doc.data().endDate, city:doc.data().city, profileName: doc.data().profileName, startDateString: doc.data().startDateString,
            endDateString: doc.data().endDateString, gender: doc.data().gender,
            submittedForApproval: doc.data().submittedForApproval,
          } 

          setSchedules((prevState) => [...prevState, object]); 


      });
  })
  .catch((error) => {
    alert("xxd23r23r - Error getting documents: " + error )
      console.log("Error getting documents: ", error);
  });

  getBaseCitySchedules()

}


      




const getBaseCitySchedules = () => {


  db.collection("users").where("baseCity", "==", city).get()
  .then((querySnapshot) => {

    querySnapshot.forEach((doc) => {


      if(doc.data().accountCheckedForQuality && doc.data().accountCheckedForQuality === false ){
        alert("account not check for quality")
      }

    
    const object = {id: doc.id, profileImage:doc.data().profileImage,email:doc.data().email, startDate:doc.data().startDate
      , endDate:doc.data().endDate, city:city, profileName: doc.data().profileName, startDateString: doc.data().startDateString,
      endDateString: doc.data().endDateString, gender: doc.data().gender, showBaseCity: doc.data().showBaseCity,
      isBaseCityCard:true,
      submittedForApproval: doc.data().submittedForApproval,
    } 

    if(doc.data().showBaseCity === false){
      
      if(auth.currentUser && auth.currentUser.email  === "benpalmer661@icloud.com"){

      alert("benpalmer661@icloud.com only alert -  show in base city found false for " + doc.data().email)
        }
      return
    } 


    setSchedules((prevState) => [...prevState, object]); 
  })
  }).catch((err) => {
    alert("Error 124xvd234 " + err)
  })



  }
           
         
         
  const pull_data = (data) => {
  
   
    if(data === null){

      if(auth.currentUser && auth.currentUser.email  === "benpalmer661@icloud.com"){
        alert("no data for city selection - will return ")
        return
      }

    }
    setCity(data)
  }




  const setTheGender = (data) => {
    console.log(data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
    setGender(data)
  }










        






  const types =["image/png","image/jpeg"]








      const noSchedulesMarkup = schedules.length <= 0? (
        <div
         style={{
          height:"100%",width:"100%",fontSize:"20px",
        backgroundColor:"grey", 
        marginTop:"5vh"
        }}>

      No Results Please Refine Search And Try Again.

        </div>
      ) : (
      null
      )
    

      const noFutureSchedulesMarkup = upcomingSchedules.length <= 0? (
        <div
         style={{
          height:"100%",width:"100%",fontSize:"20px",
          backgroundColor:"grey", 
        marginTop:"5vh"
        }}>

      No Results Please Refine Search And Try Again.

        </div>
      ) : (
      null
      )




  
  return (
    <center>
    <div
    style={{ backgroundColor:"black"}}
    >
    <div 
    className='HomeMainDiv'
style={{
  width:"80vw", 

paddingTop:"15vh",
paddingBottom:"200px",
backgroundColor:"black",
borderStyle:"solid",
borderColo:"white",
alignContent:"center",
margin: "auto",
 position: "relative"
}}
>
    

<div
style={{display:"flex", backgroundColor:"black",margin:"auto"

}}
>

<div
style={{width:"20vw"}}
>
</div>

  
<HomeSearchCitySelectionMenu
func={pull_data}

/>




<List
      className='listItemButton'
      style={{backgroundColor:"yellow"}}

      component="nav" aria-label="Device settings">
        <ListItem
         className='listItemButton'
         style={{backgroundColor:"yellow"}}
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          onClick={() => getUsersAndSchedules()}
        >
          <ListItemText 
          style={{
           
          color:"black"}}
          className='listItemText'
          primary={ "Search"} 
          

          />
         
        </ListItem>
      </List>

<div
style={{width:"20vw"}}
>
</div>
</div>




<br/>
<p
style={{fontSize:"3vh", color:"white"}}
>
Current Models

</p>

<p style={{ backgroundColor:"yellow",
        width:"20vw", 
         }}
        >
{city}
        </p>


<br/>

<div className="images"
      style={{display:"flex",
      backgroundColor:"black",
      flexWrap:"wrap"
      }}
      >

{uniqueSchedules &&
          uniqueSchedules.map((schedule, index) => {
            
    
       
            return (
<LeopardsUsersCard
getSchedules={getUsersAndSchedules}
city = {schedule.city}
profileImage = {schedule.profileImage}
startDate = {schedule.startDate}
endDate = {schedule.endDate}
startDateString = {schedule.startDateString}
endDateString = {schedule.endDateString}
email = {schedule.email}
profileName = {schedule.profileName}
gender = {schedule.gender}
id = {schedule.id}
viewProfile={true}
style={{height:"25vh"}}
isBaseCityCard={schedule.isBaseCityCard}
submittedForApproval={schedule.submittedForApproval}
/>
            );
          })}

          <br/>
          <br/>

{noSchedulesMarkup}

<br/>

      </div>


      <p
style={{fontSize:"5vh", color:"white"}}
>
   <br/>

Arriving Soon
</p>

<div className="images"
      style={{display:"flex",
      backgroundColor:"black",
      flexWrap:"wrap"}}
      >


        {upcomingSchedules &&
          upcomingSchedules.map((schedule, index) => {

            return (
<LeopardsUsersCard
getSchedules={getSchedules}
city = {schedule.city}
profileImage = {schedule.profileImage}
startDate = {schedule.startDate}
endDate = {schedule.endDate}
startDateString = {schedule.startDateString}
endDateString = {schedule.endDateString}
email = {schedule.email}
profileName = {schedule.profileName}
id = {schedule.id}
style={{height:"25vh"}}
gender={schedule.gender}
submittedForApproval={schedule.submittedForApproval}


/>
            );
          })}

          <br/>
          <br/>

{noFutureSchedulesMarkup}

<br/>

      </div>
   <br/>

   <p
style={{fontSize:"5vh", color:"white"}}
>
   <br/>

Past Escorts
</p>

<div className="images"
      style={{display:"flex",
      backgroundColor:"black",
      flexWrap:"wrap"}}
      >


        {pastSchedules &&
            pastSchedules.map((schedule, index) => {
{/* 
need to code past schedules if there a less then 4 current and 4 arriving */}

            return (
<LeopardsUsersCard
getSchedules={getSchedules}
city = {schedule.city}
profileImage = {schedule.profileImage}
startDate = {schedule.startDate}
endDate = {schedule.endDate}
startDateString = {schedule.startDateString}
endDateString = {schedule.endDateString}
email = {schedule.email}
profileName = {schedule.profileName}
id = {schedule.id}
style={{height:"25vh"}}
gender={schedule.gender}
viewProfile={true}
showBaseCity={schedule.showBaseCity}
submittedForApproval={schedule.submittedForApproval}

/>
            );
          })}

          <br/>
          <br/>

{noFutureSchedulesMarkup}

<br/>

      </div>
       

       </div>
       <div
    style={{ backgroundColor:"black", height:"100%"}}
    >
    </div>
</div>

</center>


  )





        }





