

import React, { useEffect, useState } from 'react'

import { db, auth } from "./firebase";  

import elephant from './Images/elephantChess.png'

import leopard from './Images/leopardLogoBlack.png'


import leopardClear from './Images/leopardsolo.png'


import ReactPlayer from 'react-player';


import { useHistory } from 'react-router-dom'

import "./css/ProfilePage.css"

import { v4 as uuidv4 } from 'uuid';
import LeopardsUsersCard from './LeopardsUsersCard';
import { Password } from '@mui/icons-material';


  export default function ProfilePage(props) {



const [profileName, setProfileName] = useState("Leopard Escorts");

const [aboutMe, setAboutMe] = useState("Leopard Escorts");





const [showBaseCity, setShowBaseCity] = useState();



const [schedules, setSchedules] = useState([]);
    


  const [displayImage, setDisplayImage] = useState(elephant);
  const [url, setUrl] = useState("");
  const [progress, setProgress] = useState(0);

  const [phoneNumber, setPhoneNumber] = useState("Phone Number");

  const [email, setEmail] = useState();


const [images, setImages] = useState([]);
const [urls, setUrls] = useState([]);
const [selectedImages, setSelectedImages] = useState([]);




const [inCallRates, setInCallRates] = useState("30mins GFE NA, 1 hour GFE $100, 2 hours GFE $100, Additional Hours GFE $100");
const [outcallRates, setOutCallRates] = useState("30mins GFE NA, 1 hour GFE $100, 2 hours GFE $100, Additional Hours GFE $100");



const [videoUrls, setVideoUrls] = useState([])

const [specialRates, setSpecialNotesAboutRates] = useState();

const [file, setFile] = useState(null)


const [rates, setRates] = useState([]);



const [videoUrl, setVideoUrl] = useState("");





const [profileImage, setProfileImage] = useState();




const [password, setPassword] = useState();





const [baseCity, setBaseCity] = useState();

 




const [ratesPrivate, setRatesPrivate] = useState(true);



const history = useHistory()





const onSelectFile = (event) => {
  const selectedFiles = event.target.files;
  const selectedFilesArray = Array.from(selectedFiles);

  const imagesArray = selectedFilesArray.map((file) => {
    return URL.createObjectURL(file);
  });


  setSelectedImages((previousImages) => previousImages.concat(imagesArray));

  // FOR BUG IN CHROME
  event.target.value = "";
};




     

     useEffect(() => {

      getUsersInfo()
      getSchedules()
      
           }, []);
         


    
useEffect(() => {
  getUsersProfileImages()
  
       }, []);
     


           const getSchedules = () => {

            const email = history.location.state?.email


            db.collection("users").doc(email).collection("schedule")
            .get()
            .then((querySnapshot) => {


                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());


              const object = {id: doc.id, profileImage:doc.data().profileImage,email:doc.data().email, startDate:doc.data().startDate
                , endDate:doc.data().endDate, city:doc.data().city, profileName: doc.data().profileName, startDateString: doc.data().startDateString,
                endDateString: doc.data().endDateString, gender: doc.data().gender, aboutMe: doc.data().aboutMe
              } 
       
              setSchedules((prevState) => [...prevState, object]); 
              
            })
          }).catch((error) => {
alert(`error: ${error}`)
          })

           }









         
           const getUsersInfo = () => {

            
            const email = history.location.state?.email

           
if(!email){
  alert(`this is email  ${email}`)
  history.push('/')
}

            db.collection("users").doc(email).get().then((doc) => {
              // Document was found in the cache. If no cached document exists,
              // an error will be returned to the 'catch' block below.
              console.log("Cached document data:", doc.data());
          

  if(doc.data().profileName){
    setProfileName(doc.data().profileName)
    } 

          
            
    if(doc.data().password){
      setPassword(doc.data().password)
      } 

              if(doc.data().inCallRates){
              setInCallRates(doc.data().inCallRates)
              } else {
              
              }


              if(doc.data().outcallRates){
                setOutCallRates(doc.data().outcallRates)
                              }


                              if(doc.data().phoneNumber){
                                setPhoneNumber(doc.data().phoneNumber)
                                              }
                
                                              if(doc.data().email){
                                                setEmail(doc.data().email)
                                                              }
                                
                                                              if(doc.data().baseCity){
                                                                setBaseCity(doc.data().baseCity)
                                                                              }
                                                

                                                              

              if(doc.data().specialRates){
                setSpecialNotesAboutRates(doc.data().specialRates)
                              } 



                              if(doc.data().profileImage){
                                setProfileImage(doc.data().profileImage)
                                              } 
                

                                             


                              if(doc.data().aboutMe){
                                setAboutMe(doc.data().aboutMe)
                                              } 
                                            
                                              

                                              if(doc.data().showBaseCity){
                                                setShowBaseCity(doc.data().showBaseCity)
                                                              } 
                                                            


                            
          }).catch((error) => {
              console.log("Error getting cached document:", error);
              db.collection("users").doc(auth.currentUser.email).update({"ratesPrivate":true})
          
          });
        }




        // go to this page want to allow user with no email to claim account easily !!

        // https://www.w3schools.com/js/js_popup.asp


const claimProfile = () =>  {
//  let pw = window.prompt("You Know What To Do","LEOPARD ESCORTS")

  alert("email is:" + email)

   let person = prompt("You Know What To Do", "Insert Here");
let text;

if(password == ""){
  return
}

if(password == null){
  return
}

if (person == password ) {
  alert("password correct ")
  
  alert("props.email " + email)

  history.push({
    pathname: `/ClaimAccountPage`,
    state: { email : email }
  })
 

} else {
  
alert("Hmmmm???")
 
}
}







     useEffect(() => {
  
      getUsersVideoUrls()
      
           }, []);







const getUsersVideoUrls = () => {

 
  setVideoUrls([])


  const email = history.location.state?.email

  db.collection("users").doc(email).collection("videoUrls").get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());

        console.log(doc.data().url)

        console.log(doc.data().docId)

        const object = {id: doc.id, url:doc.data().url}
        
        setVideoUrls((prevState) => [...prevState, object]); 

    });
}).catch((err) =>{
  alert("Error: " + err)
})
}

         


const getUsersProfileImages = () => {

  const email = history.location.state?.email




  setUrls([])

 

  
  db.collection("users").doc(email).collection("profileImages").get().then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());



        const profileImage = doc.data().profileImage
        


        const object = {id: doc.id, url:doc.data().profilePictureURL, private: doc.data().private}
        
        setUrls((prevState) => [...prevState, object]); 

    });
}).catch((error) => {
  alert("error getting profile images " + error)
})
}





































        






  const types =["image/png","image/jpeg"]










    

















  







  







  


  const uploadVideo = (theVidUrl) => {

 

    var vidUrl = theVidUrl.replace(/(^\w+:|^)\/\//, '');



    const id = uuidv4()
    alert(vidUrl)
  
    db.collection("users").doc(auth.currentUser.email).collection("videoUrls").doc(id).update({"url": vidUrl, "docId":id}).then(() => {
     
      alert("Video Url Updated To Database")
      getUsersVideoUrls()
   
    }).catch((error) => {
    alert("Error Updating Video Url")
                              
    
    });
    }
  







const addUrlsToFirebase = (urls) => {

  setUrls([])


  db.collection("users").doc(auth.currentUser.email).collection("profileImages").doc().set({"profilePictureURL":urls,"private":true}, { merge: true })


  getUsersProfileImages()

}











    
    
    
    
    



  



const makePrivate = (urlId) => {

console.log("make private pressed")

db.collection("users").doc(auth.currentUser.email).collection("profileImages").doc(urlId).update({"private": true})


getUsersProfileImages()

} 
          


const makePublic = (urlId) => {
  
console.log("make public pressed")

db.collection("users").doc(auth.currentUser.email).collection("profileImages").doc(urlId).update({"private": false})


getUsersProfileImages()

}




  

    




  
  return (
    <center>
    <div
    style={{ backgroundColor:"black"}}
    >
    




     


<center>
   

<div 
style={{

  maxHeight:"110vh",
width:"95vw", 
// backgroundColor:"yellow",
padding:"2vw",
objectFit:"fill",
paddingTop:"100px",
marginBottom:"10vh",
}}
>


    
<p
style={{fontSize:"8vh", color:"white", marginBottom: "1vh"}}
>

</p>

<button
style={{fontSize:"8vh", color:"white", marginBottom: "1vh", backgroundColor:"black", border:"none"}}
onClick={() => claimProfile()}
>
{profileName}
</button>



{showBaseCity && showBaseCity !== undefined? (
<p
        style={{ backgroundColor:"yellow",
        width:"20vw", 
         }}
        >
{baseCity}
        </p>
):(
  <p
        style={{ backgroundColor:"yellow",
        width:"20vw", 
         }}
        >
xo
        </p>
)
}


<br/>
<br/>

      <img
      
      style={{
       objectFit:"contain",
        maxHeight:"70vh",
       width:"95vw", 
       marginBottom:"10vh"
       }}
     
       src={profileImage || leopard}
       
        />
    

       </div>

</center>

<div>

</div>
<center>
      <div  style={{display:"flex",  flexWrap:"wrap", marginTop:"10vh"}}>


{urls.map((url, i) => (
  <div key={i} 
        style={{
      position:"relative"
        }}
        >
          <img 
          
          className='urls'
          src={url.url} 
          alt="upload" 
//           style={{
//             objectFit:"contain",
// width:  "48vw",
// backgroundSize: "cover",
// borderStyle:"solid",
//           padding:"1vh",
//           }}
          />

{url.waterMarked && url.waterMarked === true?( 
   <div

   style={{
    height:"1vh", 
   //position:"absolute",
    top:"50%", left: "25%",
    zIndex:2,
    position:"absolute"
    
    }}
   >
   
  
   <img src={leopardClear} 
          alt="leopardClear" 

          style={{
            height:"30vh", 

backgroundSize: "cover",
          padding:"1vh",
          }}
          />

   </div>
):(
  null
)}





        </div>
        
))}
</div>
</center>

      <br/>
    <br/>
    


     
<center>

<div className="images"
      style={{display:"flex",  flexWrap:"wrap"}}
      >


{ videoUrls.map((url, i) => (
<div
style={{
  //backgroundColor:"red", 
width:"49vw"}}
>
  <ReactPlayer
 
 url={url.url}
 controls={true}
 style={{padding:"1vh",width:"49vw",borderstyle:"solid", borderColor:"white"}}
 >
 
 </ReactPlayer>

 </div>

))}

</div>





{aboutMe && aboutMe !== undefined? (
<div
style={{backgroundColor:"yellow", padding:"5vh"}}
>
 <br/>
  <br/>
  <p
style={{backgroundColor:"yellow",fontSize:"5vh"}}
>
  ABOUT ME 
  </p>
  <p
style={{backgroundColor:"yellow",fontSize:"2.5vh", padding:"3vh", paddingBottom:"5vh"}}
>
  {aboutMe}
 
</p>
</div>
):(
  null
)
}

<br/>


   

                              
              
          



    <div


style={{display:"flex", }}
>
<div
style={{display:"flex", borderStyle:"solid", borderColor:"white"}}
>
<div
style={{width:"49.6vw", marginBottom:"2vh"}}
>
<p
style={{fontSize:"vmin", color:"white", }}
>
INCALL
</p>

          <ul
          style={{backgroundColor:"black"}}
          >
            {inCallRates.split(',').map((el,i) => <li
            style={{fontSize:"3vh", color:"white", marginTop:"2vh"}}
             key={i}>{el}</li> )}
          </ul>




</div>

</div>


<div
style={{display:"flex", flexWrap:"wrap", borderStyle:"solid", borderColor:"white"}}
>
<div
style={{width:"50vw"}}
>
<p
style={{fontSize:"4vmin", color:"white"}}
>
OUTCALL
</p>

<ul
          style={{backgroundColor:"black"}}
          >
            {outcallRates.split(',').map((el,i) => <li
            style={{fontSize:"3vh", color:"white", marginTop:"2vh"}}
             key={i}>{el}</li> )}
          </ul>

          


</div>

</div>

</div>



{specialRates && specialRates !== undefined? (

  <div
style={{ borderStyle:"solid", borderColor:"white",fontSize:"3vh", color:"white" }}
>
<p
style={{fontSize:"3vh", color:"white" , padding:"8vh"}}
>

{specialRates}
</p>

<br/>

<br/>

</div>


):(
  null
)}


<div>


</div> 
   


      <br/>

    <br/>


     
 


<p
style={{fontSize:"3vh", color:"white"}}
>
TEXT ONLY: {phoneNumber}
</p>

<br />
<br />
<br />


<p
style={{fontSize:"3vh", color:"white", marginBottom: "vh"}}
>
Email: {email}
</p>

<br />

{schedules && schedules.length > 0 ? (
<p
style={{fontSize:"5vh", color:"white", borderStyle:"solid", borderColor:"white", width:"60vw"}}
>Schedules</p>

):(
  null
)}

<div className="images"
      style={{display:"flex",
      // backgroundColor:"yellow",
      flexWrap:"wrap",
      width:"80vw",
      paddingBottom: "50vh"}}
      >



{schedules &&
          schedules.map((schedule, index) => {

            return (

<LeopardsUsersCard
getSchedules={getSchedules}
city = {schedule.city}
profileImage = {schedule.profileImage}
startDate = {schedule.startDate}
endDate = {schedule.endDate}
startDateString = {schedule.startDateString}
endDateString = {schedule.endDateString}
email = {schedule.email}
profileName = {schedule.profileName}
gender = {schedule.gender}
id = {schedule.id}
style={{height:"25vh"}}
viewProfile={false}
aboutMe={schedule.aboutMe}

/>

            );
          })}
          </div>

</center>


    
    


<button
       style={{
       backgroundcolor:"red",
height:"30px",
color: "white",
width:"20vw",
backgroundColor:"black",
margin: "auto",
fontSize: "1.5vh",
borderRadius: "5px",
border:"solid",
borderColor:"white",
marginBottom:"1vh",
marginTop:"1vh",

}}
     
       onClick={
    () => {    


    history.push({
        pathname: `/ReportUserPage`,
        state: { profileName : profileName }
      })

    }}
       
       >
       
       
       Report User </button>
 

    </div>







     


    </center>
  )

}


// put current country flag and original flag on this page , 



