







// import { auth, db } from './firebase'



import React, { useState} from "react";

import { useDispatch } from "react-redux";


// npm

import firebase from 'firebase';
import { setAutheticated } from './redux/actions/userActions';

// import "./css/login.css"
      
import elephant from './Images/leopard logo black.png'




export default function Login() {

  

  const [password, setPassword] = useState("");

  const [email, setEmail] = useState("");

    const dispatch = useDispatch();



    
const goToForgotPasswordPage = () => {
  window.location.href = "/forgotPassword"
      }

const handleSubmit = (event) => {
    event.preventDefault();
    


  
    


firebase.auth().signInWithEmailAndPassword( email, password)
  .then((userCredential) => {
    // Signed in
    var user = userCredential.user;
  
console.log("userCredentials: " + JSON.stringify(user))


  })
  .then(() => {
   

    dispatch(setAutheticated());

    
      window.location.href = '/';
   
  })
  .catch((error) => {
  
    alert("this is error" + error.code)

  });

}  

    return (
            
                   
        
      <center>
                        <div 
                        

                        style={{
                        //   backgroundColor
                        // :"yellow",

                        //   backgroundColor
                        // :"white",

                          backgroundColor
                        :"black",


                        height:"100vh",
                        width: '100vw',
                        
                        }}
                        >
                           
                          
                            
<img 
className="elephantChessImage"
src={elephant} alt="elephant" 
style={{height:"18vh",objectFit:"contain", borderRadius:"1vh"}}
/>

           
                  

        
        <br/>
                       
                          
        
                   
                             <input 
                             
                             name="email" 
                             type="email" 
                             label="Email" 
                          placeholder="Email"
                             value={email}
                             onChange={e => setEmail(e.target.value)}
                           

                             style={{
          
          borderStyle:"solid",
      color: "white",
      backgroundColor:"black",
      borderRadius: "5px",
      border:"solid",
      borderColor:"white",
      padding:"1vh",
      width:"40vw",
     
          }}
                           
                                                  />

                                                  <br/>
                                                  
        
                             <input
                             placeholder="Password"
                     
                             id='password' 
                             name="password" 
                          
                             label="Password" 
                            
                             value={password}
                             onChange={e => setPassword(e.target.value)}
                             fullWidth
                           
                             style={{
          marginTop:"1vh",
          borderStyle:"solid",
      color: "white",
      backgroundColor:"black",
      borderRadius: "5px",
      border:"solid",
      borderColor:"white",
      padding:"1vh",
      width:"40vw"
          }}
                             />     

                          
<br/>
<br/>
        
        
                             <button 
                            
                               style={{
          
          borderStyle:"solid",
      color: "white",
      backgroundColor:"black",
      borderRadius: "5px",
      border:"solid",
      borderColor:"white",
      padding:"1vh",
      width:"15vw",
      marginTop:"5vh"
          }}
                             onClick={handleSubmit}
                            
                          
                               >Login</button>
        
        
        
        <br/>
        <br/>

       <button onClick={() => goToForgotPasswordPage()}
  style={{
          
          borderStyle:"none",
      color: "white",
      backgroundColor:"black",
      padding:"1vh",
      width:"15vw"
          }}
       >
        
          Forgot Password? 
       
       
        </button>  

    
        <br/>
        <br/>
   
<p
style={{fontSize:"3vh", color:"white",}}
>
www.leapordescorts.app © 2014
</p>


        
                     
                       
                    </div>
                    </center>    
                )
}
























