



import React from 'react';
import './App.css'

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import createTheme from '@material-ui/core/styles/createTheme'
import themeFile from './util/theme';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from './firebase'
import AuthRoute from './util/AuthRoute'

import store from './redux/store';
import { Provider } from "react-redux";
import Login from './login'

import  Signup  from './signup';



import ProfilePage from './ProfilePage'

import Home from './Home'

import EditProfilePage from './EditProfilePage'


import CreateAccountPage from './CreateAccountPage'


import ClaimAccountPage from './ClaimAccountPage'


import ReportUserPage from './ReportUserPage'

import AllUsersPage from './AllUsersPage'

import PendingApprovalsPage from './PendingApprovalsPage'

import MyProfilePage from './MyProfilePage'

// import StripeDepositsPage from './payments/StripeDepositsPage'
     
//we don't put brackets on navbar like {navbar } because it doesn't connect it to the right component/ if we put { navbar } the navbar component is not connected to
//the redux store, so it does not have access to redux store data.
import  Navbar from './Navbar';
import ForgotPassword from './forgotPassword'

const theme = createTheme(themeFile);


export default function App() {


// eslint-disable-next-line
    const [user, loading, error] = useAuthState(auth)

    if (loading) {
        return 'loading ...'
        
    }



    return (

<MuiThemeProvider theme={theme}>

<Provider store={store}>
        <Router>

      
        <Navbar /> 
               
    
        <Switch>
        
        
        <Route exact path="/ClaimAccountPage" component={ClaimAccountPage}/>



        <Route exact path="/ReportUserPage" component={ReportUserPage}/>

        
        
        <Route exact path="/PendingApprovalsPage" component={PendingApprovalsPage}/>


        <Route exact path="/AllUsersPage" component={AllUsersPage}/>


       <Route exact path="/CreateAccountPage" component={CreateAccountPage}/>

       <Route exact path="/" component={Home}/>

       <Route exact path="/MyProfilePage" component={MyProfilePage}/>

       <Route exact path="/EditProfilePage" component={EditProfilePage}/>

   
                    <Route exact path="/ProfilePage" component={ProfilePage}/>









       

       
       
    
                <AuthRoute exact path="/login"  >
         <Login />

</AuthRoute>

<Route exact path="/signup" >
         <Signup/>
</Route>

               

              


       

                    </Switch>

        </Router>
</Provider>
</MuiThemeProvider>
    )
}