
//types.js
//USER REDUCER TYPES 
export const SET_AUTHENTICATED =  "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED =  "SET_UNAUTHENTICATED";
export const SET_USER =  "SET_USER";
export const LOADING_USER = "LOADING_USER";
export const SET_LOADING_FALSE = "SET_LOADING_FALSE";
export const SET_WHITE_CLOCK_TIME = "SET_WHITE_CLOCK_TIME";
export const SET_BLACK_CLOCK_TIME = "SET_BLACK_CLOCK_TIME";
export const SET_CLOCK_VALUES_AND_TIMESTAMP = "SET_CLOCK_VALUES_AND_TIMESTAMP";
export const SET_PLAYERS_COLORS = "SET_PLAYERS_COLORS";
export const SET_PLAYERS_RATING = "SET_PLAYERS_RATING";
export const SET_BOARD = "SET_BOARD";

export const SET_FEN = "SET_FEN";





export const SET_PLAYERS_THREE_MINUTE_RATING = "SET_PLAYERS_THREE_MINUTE_RATING";


export const SET_PLAYERS_FIVE_MINUTE_RATING = "SET_PLAYERS_FIVE_MINUTE_RATING";



export const SET_ORIGINAL_COUNTRY_CODE = "SET_ORIGINAL_COUNTRY_CODE";


export const SET_COUNTRY_CODE = "SET_COUNTRY_CODE";



export const SET_WIN_STREAK = "SET_WIN_STREAK";

export const SET_WIN_STREAK_VS_ROBOT = "SET_WIN_STREAK_VS_ROBOT"






//UI REDUCER TYPES
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";


//DATA REDUCER TYPES

export const SET_TOTAL_LOSES = "SET_TOTAL_LOSES";
export const SET_TOTAL_WINS = "SET_TOTAL_WINS";








export const SET_STATE = "SET_STATE";






 export const LOADING_DATA = "LOADING_DATA";



export const SET_JOB_POST = "SET_JOB_POST";
export const STOP_LOADING_UI = "STOP_LOADING_UI";


    export const   USER_LOGIN = 'USER_LOGIN';
    export const  USER_LOGOUT = 'USER_LOGOUT';



    export const GET_REALTIME_USERS = 'GET_REALTIME_USERS';
    export const GET_REALTIME_MESSAGES = 'GET_REALTIME_MESSAGES';
    export const GET_REALTIME_CONVERSATIONS = 'GET_REALTIME_CONVERSATIONS';
    export const GET_CONVERSATIONS  = "GET_CONVERSATIONS";
    export const SET_OTHER_CHAT_USER = "SET_OTHER_CHAT_USER";
    export const GET_MESSAGES = "GET_MESSAGES";
    export const UPDATE_USER = "UPDATE_USER";
    export const SET_ACCOUNTS = "SET_ACCOUNTS";



    export const UPDATE_USER_INFO = "UPDATE_USER_INFO";



    export const SET_USERS_JOB_POSTS = "SET_USERS_JOB_POSTS";
    


    export const DELETE_CONVERSATION = "DELETE_CONVERSATION";
    


    export const DEACTIVATE_ACCOUNT = "DEACTIVATE_ACCOUNT";
    export const ACTIVATE_ACCOUNT = "ACTIVATE_ACCOUNT";
    export const OTHER_CHAT_USERS_DETAILS = "OTHER_CHAT_USERS_DETAILS";
    export const SET_TRANSACTIONS = "SET_TRANSACTIONS";


    export const SET_PLAYERS_USERNAME = "SET_PLAYERS_USERNAME";
    export const SET_PLAYER = "SET_PLAYER";
    export const SET_KNOWN_WALLET_ADDRESS = "SET_KNOWN_WALLET_ADDRESS";
    export const SET_FIRESTORE_SERVER_TIMESTAMP = "SET_FIRESTORE_SERVER_TIMESTAMP";



    export const SET_PROFILE_IMAGE = "SET_PROFILE_IMAGE";

    