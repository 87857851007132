import firebase from "firebase/app";
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'


//you can either add your firebase config directly like in the tutorial or can also add it as an 
//json string like here https://create-react-app.dev/docs/adding-custom-environment-variables/

// const config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)



// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAn6G2OJGbLH6x6Y8ImZlkdOKSU9Etiv18",
  authDomain: "leopard-63f7d.firebaseapp.com",
  projectId: "leopard-63f7d",
  storageBucket: "leopard-63f7d.appspot.com",
  messagingSenderId: "29505480200",
  appId: "1:29505480200:web:6d87f1867a13ad64fdd3bd",
  measurementId: "G-CFBKMV6RP1"
};



// const firebaseConfig = config;
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

 export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const db = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()

export default firebase














