
import {  LOADING_DATA,SET_STATE, 
 GET_CONVERSATIONS, GET_MESSAGES, SET_ACCOUNTS,SET_TRANSACTIONS
} from '../types'

const initialState= {
    jobPosts: [],
    jobPost: [],
    jobPostReplys: [],
    loading: false,
};



export default function dataReducer(state = initialState, action){


    switch(action.type){


       

        case SET_TRANSACTIONS:
            return {
                ...state,
                transactions: action.payload,
            };
            
    
        case LOADING_DATA:
            return {
                ...state,
                loading: true,
            };
            
           

              
                




case SET_STATE:
return {
    ...state,
//   state: action.payload
};









case GET_CONVERSATIONS:
return {
    ...state,
  conversations: action.payload
};




case GET_MESSAGES:
return {
    ...state,
  messages: action.payload
};




case SET_ACCOUNTS:
    return{
        ...state,
        accounts: action.payload
    };









                default:
                    return state;
    }
    }